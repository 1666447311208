<div class="col-md-6" *ngIf="visible" style="margin-bottom: 40px;">

  <h4 class="mb-0">Ändra lösenord</h4>

  <form class="form" role="form" id="formResetPassword" method="POST" autocomplete="off">
    <input autocomplete="off" name="hidden" type="text" style="display:none;">
    <div class="form-group">
      <input type="text" class="form-control form-control-lg rounded-0" [(ngModel)]="id" name="resetid" placeholder="Användar-ID">
    </div>
    <div class="form-group">
      <input type="password" class="form-control form-control-lg rounded-0" [(ngModel)]="psw" name="resetpsw" placeholder="Nytt lösenord">
    </div>
    <app-button [debug]="false" [text]="'Ändra lösenord'" [color]="'info'" (onClick)="changePassword()"></app-button>
    <app-button [text]="'Stäng'" [debug]="false" [useSpinner]="false" [right]="true" (onClick)="cancel()"></app-button>
  </form>

  <div class="alert alert-danger mt-1" role="alert" *ngIf="error">
    Felaktiga uppgifter
  </div>

</div>
<!--<mat-spinner *ngIf="isLoading"></mat-spinner>-->
