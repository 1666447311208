<div class="container-fluid">

  <div class="logo-container hidden-print">
    <div class="row">
      <div class="container">
        <div class="col-lg-10">
          <h2 *ngIf="!showTestHeader">Dahl - Administration</h2>
          <h2 *ngIf="showTestHeader"><marquee>Dahl - Administration TEST</marquee></h2>
          <div class="logo">

          </div>
        </div>
        <div class="col-lg-6 text-right">
        </div>
      </div>
    </div>
    <app-nav></app-nav>
  </div>
  <div class="container" id="main">
    <div ui-view></div>
    <div ui-view="scroll"></div>
    <div class="questionui" ui-view="question"></div>
    <router-outlet></router-outlet>
  </div>
</div>
