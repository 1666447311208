import { Component } from '@angular/core';
import { DataService } from '../../services/data-service.service';
import { BaseComponent } from 'src/app/components/base-component'

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent extends BaseComponent {
  isLoading: boolean;

  constructor(private service: DataService) {
    super();

  }

}


