import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base-component';
import { DataService } from '../../../services/data-service.service';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-criterias',
  templateUrl: './criterias.component.html',
  styleUrls: ['./criterias.component.css']
})
export class CriteriasComponent extends BaseComponent implements OnInit {
  criterias: any;
  isLoading = true;
  updating = false;

  constructor(private readonly service: DataService, private readonly notify: NotificationService) {
    super();
  }

  ngOnInit() {
    this.load();
    $(window).scroll(function () {
      //$('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
      $('#appButton').css({ top: $(this).scrollTop() });
    });
  }

  load() {
    this.service.getCriterias().pipe(takeUntil(this.destroyed$)).subscribe(criterias => {
      this.criterias = criterias;
      this.isLoading = false;
    });
  }

  save() {
    this.updating = true;
    this.service.saveCriterias(this.criterias).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.updating = false;
      this.notify.success('translationUpdated');
      this.load();
    });
  }
}
