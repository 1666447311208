import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListComponent } from './pages/userunits/list/list.component';
import { AddUnitComponent } from './pages/userunits/add-unit/add-unit.component';
import { AddUserComponent } from './pages/userunits/add-user/add-user.component';
import { EditUserComponent } from './pages/userunits/edit-user/edit-user.component';
import { CriteriasComponent } from './pages/translation/criterias/criterias.component';
import { GroupsComponent } from './pages/translation/groups/groups.component';
import { TranslateLabelComponent } from './pages/translation/translate-label/translate-label.component';
import { TranslateQuestionComponent } from './pages/translation/translate-question/translate-question.component';
import { TranslateQuestionTypeComponent } from './pages/translation/translate-question-type/translate-question-type.component';
import { GroupItemComponent } from './pages/translation/groups/group-item/group-item.component';
import { LoginComponent } from './pages/login/login.component';
import { ButtonComponent } from './components/button/button.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { NavComponent } from './components/nav/nav.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SuccessComponent } from './components/success/success.component';
import { TranslateLabelItemComponent } from './pages/translation/translate-label/translate-label-item/translate-label-item.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuardService } from './services/auth-guard.service';
import { DataService } from './services/data-service.service';
import { LoginService } from './services/login.service';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { NotificationService } from './services/notification.service';
import { environment } from 'src/environments/environment';
import { BackgroundComponent } from './pages/translation/background/background.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { UserGuideComponent } from './pages/user-guide/user-guide.component';
import { LayoutGuideComponent } from './pages/layout/layout-guide/layout-guide.component';
import { LayoutMainComponent } from './pages/layout/layout-main/layout-main.component';
import { SubstanceListComponent } from './pages/translation/substance-list/substance-list.component';
import { SubstanceItemComponent } from './pages/translation/substance-list/substance-item/substance-item.component';
import { TranslateQuestionCategoryComponent } from './pages/translation/translate-question-category/translate-question-category.component';


export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrlTranslate, '');
}


@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    AddUnitComponent,
    AddUserComponent,
    EditUserComponent,
    CriteriasComponent,
    GroupsComponent,
    TranslateLabelComponent,
    TranslateQuestionComponent,
    TranslateQuestionTypeComponent,
    GroupItemComponent,
    LoginComponent,
    ButtonComponent,
    DialogComponent,
    ErrorComponent,
    NavComponent,
    ResetPasswordComponent,
    SuccessComponent,
    TranslateLabelItemComponent,
    BackgroundComponent,
    LandingPageComponent,
    UserGuideComponent,
    LayoutGuideComponent,
    LayoutMainComponent,
    SubstanceListComponent,
    SubstanceItemComponent,
    TranslateQuestionCategoryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [DataService, LoginService, AuthGuardService, HttpClient, { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true }, NotificationService],
  bootstrap: [AppComponent],
  entryComponents: [SuccessComponent, ErrorComponent, DialogComponent]
})
export class AppModule { }
