<div class="row">
  <div class="col-lg-12" *ngIf="!isLoading">
    <h2>Question type</h2>
    <p>A question type is a group of questions that work in the same way. For example one question type has the same option. If an alternative is not translated, the English name is used</p>
    <!--<p>En frågetyp är en grupp frågor som fungerar på samma sätt. T.ex. har en frågetyp samma alternativ. Om ett alternativ inte är översatt så används det svenska namnet</p>-->
    <select class="form-control mb-2" [(ngModel)]="selectedQuestionType" (change)="changeQuestionType()">
      <option [hidden]="!t.GroupedItems.length" [value]="t.Id" *ngFor="let t of data.QuestionTypes">{{t.Name}}</option>
    </select>

    <div class="panel-group">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">
            <a data-toggle="collapse" href="#collapse1">
              Questions related to this question type <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
              </svg>
            </a>
          </h3>
        </div>
        <div id="collapse1" class="panel-collapse collapse">
          <ul class="list-group" *ngFor="let q of questions">
            <li class="list-group-item">{{q.OrderName}} - {{q.QuestionText}}</li>
          </ul>
        </div>
      </div>
    </div>

    <h3>Translations</h3>
    <!--<p>Saknas översättnings-id betyder det att ingen översättning är skapad för detta ord.</p>-->
    <p>if a translation-Id is missing, it means that no translation is created for this word.</p>
    <p>You can only translate the language you entered into your usersettings.</p>
    <div class="col-md-11 p-0 float-left">
      <table class="table table-striped">
        <thead>
          <tr>
            <th><i>Translation-Id</i></th>
            <th scope="col">Language</th>
            <th scope="col">English text (used as default)</th>
            <th scope="col">Translation</th>
          </tr>
        </thead>
        <tbody style="border: 1.1px solid #ced4da;">
          <ng-container *ngFor="let c of cultureCodes">
            <tr *ngFor="let co of c.Items">
              <td><i>{{co.TranslationId == null ? 'Missing' : co.TranslationId}}</i></td>
              <td>{{co.LanguageName}}</td>
              <td><textarea class="form-control py-0 px-1" readonly="readonly">{{co.CodeNameSv}}</textarea></td>
              <td><textarea class="form-control py-0 px-1" [(ngModel)]="co.CodeNameTranslation" [readonly]="!co.Editable">{{co.CodeNameTranslation}}</textarea></td>
            </tr>
            <tr>
              <td class="spacer" colspan="4"></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="col-md-1 float-left pl-0">
      <app-button id="appButton" [text]="'Save'" [success]="success" [useIcon]="true" [useConfirm]="true" (onClick)="saveTranslation()" [debug]="false" [useSpinner]="true" [showSpinner]="updating" [color]="'success btn-lg'"></app-button>
    </div>
  </div>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
