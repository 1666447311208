/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./background.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/forms";
import * as i8 from "../../../components/button/button.component.ngfactory";
import * as i9 from "../../../components/button/button.component";
import * as i10 from "./background.component";
import * as i11 from "../../../services/data-service.service";
import * as i12 from "../../../services/notification.service";
var styles_BackgroundComponent = [i0.styles];
var RenderType_BackgroundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackgroundComponent, data: {} });
export { RenderType_BackgroundComponent as RenderType_BackgroundComponent };
function View_BackgroundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_BackgroundComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-12 mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Question text"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "textarea", [["class", "form-control"]], [[8, "readOnly", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_v.context.$implicit.CbText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(12, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null)], function (_ck, _v) { var currVal_9 = _v.context.$implicit.CbText; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.CultureName; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_v.context.$implicit.Editable; var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_BackgroundComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Question ", ": ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_4)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.CodeBackgrounds; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.OrderName; var currVal_1 = _v.context.$implicit.Text; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_BackgroundComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Translations - Background texts"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You can only translate the language you entered into your usersettings."])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-11"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_3)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-md-1 pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-button", [["id", "appButton"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(12, 4308992, null, 0, i9.ButtonComponent, [], { text: [0, "text"], color: [1, "color"], useIcon: [2, "useIcon"], useConfirm: [3, "useConfirm"], useSpinner: [4, "useSpinner"], spinnerText: [5, "spinnerText"], showSpinner: [6, "showSpinner"], debug: [7, "debug"], success: [8, "success"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.codes; _ck(_v, 9, 0, currVal_0); var currVal_1 = "Save"; var currVal_2 = "success btn-lg"; var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; var currVal_6 = "Save"; var currVal_7 = _co.updating; var currVal_8 = false; var currVal_9 = _co.success; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_BackgroundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BackgroundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-background", [], null, null, null, View_BackgroundComponent_0, RenderType_BackgroundComponent)), i1.ɵdid(1, 245760, null, 0, i10.BackgroundComponent, [i11.DataService, i12.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundComponentNgFactory = i1.ɵccf("app-background", i10.BackgroundComponent, View_BackgroundComponent_Host_0, {}, {}, []);
export { BackgroundComponentNgFactory as BackgroundComponentNgFactory };
