import * as tslib_1 from "tslib";
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
var TranslateQuestionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TranslateQuestionComponent, _super);
    function TranslateQuestionComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.cultureQuestions = new Array();
        _this.success = false;
        _this.isLoading = true;
        _this.load();
        return _this;
    }
    TranslateQuestionComponent.prototype.load = function () {
        var _this = this;
        this.service.listQuestionTranslations().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            console.log('translation question', response);
            _this.questions = response;
            _this.isLoading = false;
            $(window).scroll(function () {
                $('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
            });
        });
    };
    TranslateQuestionComponent.prototype.saveTranslation = function () {
        var _this = this;
        this.updating = true;
        var array = [];
        this.questions.map(function (d) { return Array.prototype.push.apply(array, d.Items); });
        console.log('array', array);
        this.service.saveQuestionTranslations(array).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.load();
            _this.updating = false;
            _this.notify.success('translationUpdated');
        });
    };
    return TranslateQuestionComponent;
}(BaseComponent));
export { TranslateQuestionComponent };
