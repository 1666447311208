<div *ngIf="!isLoading">
  <h2 class="text-center">Substance list</h2>
  <hr />
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <label for="groups" class="col-sm-2 col-form-label">Drug:</label>
        <div class="col-sm-8">
          <select class="form-control" id="drugGroup" [(ngModel)]="selectedGroup" (ngModelChange)="onGroupChanged()">
            <option *ngFor="let drug of drugGroup" [value]="drug.Id">{{drug.Name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

 
  <div class="row" [hidden]="!isAdmin">
    <div class="col-md-6">
      <div class="form-group row">
        <label for="substanceName" class="col-sm-2 col-form-label">Substance:</label>
        <div class="col-sm-8">
          <input type="text" #substanceName class="form-control" id="substanceName" placeholder="Substance name" />
        </div>
        <div style="padding-left: 15px;">
          <button type="submit" class="btn btn-primary mb-2" style="margin-top: 20px;" (click)="addNewSubstance(substanceName.value, selectedGroup)">Add new substance</button>
          <p>The new substance will be added to the selected drug group.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row spacer">
    <div class="col-lg-12">
      <p>You can only edit and translate the language you entered into your usersettings.</p>

      <table class="table">
        <thead>
          <tr>
            <th scope="col" style="padding: 35px;"></th>
            <th scope="col" class="w-25">Substance</th>
            <th scope="col" class="w-25">Language</th>
            <th scope="col" class="w-50">Text</th>

            <th scope="col"></th>
          </tr>
        </thead>

        <ng-container *ngFor="let substanceGroup of substanceGroups; let i = index">
          <tbody [ngClass]="{ 'block' : (i + 1) % 2 == 0 }" class="block-two">
            <tr>
              <td colspan="4">
                <div style="margin-left: 20px;">
                  <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                         [ngModel]="substanceGroup.Active" (ngModelChange)="onCheckChange(substanceGroup)" [disabled]="!substanceGroup.IsAdmin">
                  <label class="form-check-label" for="flexCheckDefault">
                    Active
                  </label>
                </div>

              </td>
              <td colspan="1">
                <div class="dropdown">
                  <button class="btn btn-secondary btn-move btn-sm dropdown-toggle" [hidden]="!substanceGroup.IsAdmin" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Move to
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a *ngFor="let drug of drugGroup" class="dropdown-item" (click)="moveSubstanceGroup(drug.Id, substanceGroup)">{{drug.Name}}</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngFor="let lang of substanceGroup.SubstanceLanguages">
              <td></td>
              <td>{{substanceGroup.SubstanceName}}</td>
              <app-substance-item [selectedGroup]="selectedGroup" [substanceGroup]="substanceGroup" [drugGroup]="drugGroup" [lang]="lang" [index]="i" (onChange)="loadTranslations()"></app-substance-item>
            </tr>
          </tbody>
        </ng-container>

      </table>
    </div>
  </div>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
