import * as tslib_1 from "tslib";
import { BaseComponent } from '../../../components/base-component';
import { DataService } from '../../../services/data-service.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
var TranslateLabelComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TranslateLabelComponent, _super);
    function TranslateLabelComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.selectedGroup = 1;
        _this.isLoading = true;
        _this.tokenKey = "admin_token";
        _this.loadTranslations();
        var token = localStorage.getItem(_this.tokenKey);
        if (token != undefined) {
            var tokenObject = JSON.parse(token);
            var userRoles = tokenObject.roles;
            if (userRoles.includes('Admin')) {
                _this.userRoleAuth = true;
            }
            else {
                _this.userRoleAuth = false;
            }
        }
        return _this;
    }
    TranslateLabelComponent.prototype.onGroupChanged = function () {
        var _this = this;
        this.translationGroups = this.groups.filter(function (g) { return g.Id == _this.selectedGroup; })[0].Translations;
    };
    TranslateLabelComponent.prototype.loadTranslations = function () {
        var _this = this;
        this.service.getGroups(true).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.groups = response;
            if (_this.userRoleAuth == false) {
                _this.groups = _this.groups.filter(function (g) { return g.Key !== 'licensAgreement'; });
            }
            _this.translationGroups = _this.groups.filter(function (g) { return g.Id == _this.selectedGroup; })[0].Translations;
            _this.isLoading = false;
            console.log('All groups: ', _this.groups);
            console.log('All translationGroups: ', _this.translationGroups);
        });
    };
    TranslateLabelComponent.prototype.moveTranslationGroup = function (groupId, translationGroup) {
        var _this = this;
        console.log(groupId, translationGroup);
        this.service.moveTranslationGroup(groupId, translationGroup.key)
            .subscribe(function (response) {
            _this.notify.success('translation group was moved');
            var index = _this.translationGroups.findIndex(function (tg) { return tg.key == translationGroup.key; });
            var movedTranslationGroup = _this.translationGroups.splice(index, 1);
            var tGroup = _this.groups.find(function (tg) { return tg.Id == groupId; });
            tGroup.Translations.push(movedTranslationGroup[0]);
            var tGroupIndex = _this.groups.findIndex(function (tg) { return tg.Id == groupId; });
            _this.groups.splice(tGroupIndex, 1, tGroup);
        });
    };
    return TranslateLabelComponent;
}(BaseComponent));
export { TranslateLabelComponent };
