import * as tslib_1 from "tslib";
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
var TranslateQuestionTypeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TranslateQuestionTypeComponent, _super);
    function TranslateQuestionTypeComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.cultureCodes = new Array();
        _this.data = [];
        _this.questions = [];
        _this.allQuestions = [];
        _this.isLoading = true;
        _this.updating = false;
        _this.saveSuccess = false;
        _this.load();
        return _this;
    }
    TranslateQuestionTypeComponent.prototype.load = function () {
        var _this = this;
        this.service.listQuestionTypes().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.data = response;
            console.log('data', _this.data);
            _this.allQuestions = _this.data.Questions;
            var selected = _this.data.QuestionTypes[0];
            _this.selectedQuestionType = selected.Id;
            _this.cultureCodes = selected.GroupedItems;
            _this.questions = _this.allQuestions.filter(function (x) { return x.QuestionTypeId == _this.selectedQuestionType; });
            _this.isLoading = false;
            $(window).scroll(function () {
                $('#appButton').css({ top: $(this).scrollTop() });
            });
        });
    };
    TranslateQuestionTypeComponent.prototype.changeQuestionType = function () {
        var _this = this;
        this.cultureCodes = this.data.QuestionTypes.find(function (x) { return x.Id == _this.selectedQuestionType; }).GroupedItems;
        this.questions = this.allQuestions.filter(function (x) { return x.QuestionTypeId == _this.selectedQuestionType; });
    };
    TranslateQuestionTypeComponent.prototype.saveTranslation = function () {
        var _this = this;
        this.updating = true;
        var items = [];
        // this.data[0].GroupedItems.filter(x => x.Items.length > 0).map(y => Array.prototype.push.apply(items, y.Items));
        // var items = this.data.QuestionTypes.find(x => x.Id == this.selectedQuestionType).GroupedItems.map(y => y.Items);
        this.data.QuestionTypes.find(function (x) { return x.Id == _this.selectedQuestionType; }).GroupedItems.map(function (y) { return Array.prototype.push.apply(items, y.Items); });
        this.service.saveQuestionTypeTranslations(items).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.updating = false;
            _this.loadAfterSaved();
            _this.notify.success('translationUpdated');
        });
    };
    TranslateQuestionTypeComponent.prototype.loadAfterSaved = function () {
        var _this = this;
        this.service.listQuestionTypes().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.data = response;
            console.log('data', _this.data);
            _this.allQuestions = _this.data.Questions;
            var selected = _this.data.QuestionTypes.find(function (x) { return x.Id == _this.selectedQuestionType; });
            _this.selectedQuestionType = selected.Id;
            _this.cultureCodes = selected.GroupedItems;
            _this.questions = _this.allQuestions.filter(function (x) { return x.QuestionTypeId == _this.selectedQuestionType; });
            _this.isLoading = false;
            $(window).scroll(function () {
                $('#appButton').css({ top: $(this).scrollTop() });
            });
        });
    };
    //Loads all translations of the selected question
    TranslateQuestionTypeComponent.prototype.loadCultureQuestion = function (questionId) {
    };
    return TranslateQuestionTypeComponent;
}(BaseComponent));
export { TranslateQuestionTypeComponent };
