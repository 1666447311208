/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClasses; _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-check fa-1x"]], null, null, null, null, null))], null, null); }
function View_ButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-sync-alt fa-1x fa-spin"]], null, null, null, null, null))], null, null); }
function View_ButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-sunglasses"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Debug"]))], null, null); }
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "button", [], [[8, "type", 0], [8, "disabled", 0], [8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.useIcon; _ck(_v, 3, 0, currVal_3); var currVal_5 = _co.success; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.showSpinner; _ck(_v, 8, 0, currVal_6); var currVal_7 = _co.debug; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.typeSubmit ? "submit" : "button"); var currVal_1 = _co.disabled; var currVal_2 = _co.classNames; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.text; _ck(_v, 4, 0, currVal_4); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 4308992, null, 0, i3.ButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i3.ButtonComponent, View_ButtonComponent_Host_0, { text: "text", color: "color", useIcon: "useIcon", typeSubmit: "typeSubmit", icon: "icon", size: "size", useConfirm: "useConfirm", confirmText: "confirmText", right: "right", useSpinner: "useSpinner", spinnerType: "spinnerType", spinnerText: "spinnerText", showSpinner: "showSpinner", debug: "debug", success: "success", disabled: "disabled" }, { onClick: "onClick", onConfirmEvent: "onConfirmEvent" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
