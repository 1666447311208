import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { DataService } from 'src/app/services/data-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../services/login.service';

declare const navigator: any;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  env: any = environment;
  private tokenKey: string = "admin_token";
  tokenObject: any;
  rolesArray: any;
  adminRole: boolean;
  translatorRole: boolean;
  regLink: string = 'hello world !!';
  showTestText: boolean = false;

  totalInterviews: number;
  interviewsThisMonth: number;
  interviewsThisYear: number;
  interviewsPerMonth: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  interviewsPerMonthLastYear: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  totalUsers: number;
  activeUsers: number;
  inactiveUsers: number;

  showRegLink: boolean = false;


  constructor(private loginService: LoginService, private service: DataService, private readonly notify: NotificationService) {
    if (this.env.test == true) {
      this.showTestText == true;
    }

    var token = localStorage.getItem(this.tokenKey);
    if (token != undefined) {
      this.tokenObject = JSON.parse(token);
      this.rolesArray = this.tokenObject.roles;

      if (this.rolesArray.includes('Admin', 'SuperAdmin')) {
        this.adminRole = true;
      };

      if (this.rolesArray.includes('Translator')) {
        this.translatorRole = true;
      };
    }

  }

  ngOnInit() {

    if (this.adminRole == true) {
    this.service.getUserCount().subscribe(data => {
      this.totalUsers = data.TotalUsers;
      this.activeUsers = data.ActiveUsers;
      this.inactiveUsers = data.InactiveUsers;
      console.log(data)
      this.setDoughnutChart();
    });

    this.service.getInterviewsCount().subscribe(data => {
      this.totalInterviews = data.TotalInterviews;
      this.interviewsThisMonth = data.InterviewsThisMonth;
      this.interviewsThisYear = data.InterviewsThisYear;
      this.interviewsPerMonth = data.InterviewsPerMonth;
      this.interviewsPerMonthLastYear = data.InterviewsPerMonthLastYear;
      console.log(data)
      this.setLineChart();
    });
  }

  }

  setDoughnutChart() {
    const c = document.getElementById('DoughnutChart') as HTMLCanvasElement;
    const DoughnutChart = new Chart(c, {
      type: 'doughnut',
      data: {
        labels: [
          'Active',
          'Inactive'
        ],
        datasets: [{
          data: [this.activeUsers, this.inactiveUsers],
          backgroundColor: [
            'rgba(75, 192, 192, 0.5)',
            'rgba(201, 203, 207, 0.5)'
            
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(201, 203, 207, 1)'
          ],
          hoverOffset: 4
        }]
      }
    });
  }

  setLineChart() {
    const ctx = document.getElementById('LineChart') as HTMLCanvasElement;
    const LineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
          label: 'This year',
          data: this.interviewsPerMonth,
          backgroundColor: 'rgb(255, 205, 86, 0.2)',
          borderColor: 'rgb(255, 205, 86, 1)',
        },

        {
          label: 'Last year',
          data: this.interviewsPerMonthLastYear,
          backgroundColor: 'rgba(201, 203, 207, 0.2)',
          borderColor: 'rgba(201, 203, 207, 1)',
        }  
      ]
      },
      options: {}
    });
  }


  genereteRegLink() {
    this.service.generateRegLink().subscribe(res => {
      this.showRegLink = true;
      this.regLink = res;
    })
  }

  copyLink(regLink) {
    if (navigator.permissions) {
      navigator.clipboard.writeText(regLink).then().catch(e => console.error(e));
      this.notify.success('copied to clipboard');
    }

  }

}
