import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../../components/base-component';
import { DataService } from '../../../../services/data-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-translate-label-item',
  templateUrl: './translate-label-item.component.html',
  styleUrls: ['./translate-label-item.component.css']
})
export class TranslateLabelItemComponent extends BaseComponent {
  @Input() lang: any;
  @Input() translationGroup: any;
  @Input() groups: { Id: number, Key: string, Name: string };
  @Input() selectedGroup: number;
  @Input() index: number;
  @Output() onChange = new EventEmitter();
  inEdit = false;

  constructor(private readonly service: DataService, private readonly notify: NotificationService) {
    super();
  }

  //moveTranslation(groupId: number) {
  //  this.service.moveTranslation(this.translationGroup.keyVal, this.translationGroup.Culture.Id, this.selectedGroup, groupId)
  //    .pipe(takeUntil(this.destroyed$))
  //    .subscribe(response => {
  //      this.onChange.emit();
  //      this.notify.success('translationMoved');
  //  });
  //}

  updateTranslation() {
    this.service.updateTranslation(this.lang.keyVal, this.lang.Culture.Id, this.selectedGroup, this.lang.val)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(response => {
        this.inEdit = false;
        this.onChange.emit();
        this.notify.success('translationUpdated');
    });
  }

  toggleInEdit() {
    this.inEdit = !this.inEdit;
  }
}
