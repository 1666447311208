import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';

export function customValidateArray(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    let valid: boolean = false;

    formArray.controls.forEach((x: FormControl) => {
      if (x.value.selected) {
        valid = true;
      }
    })
    return valid ? null : { error: 'Välj minst en roll' }
  }
};

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent extends BaseComponent implements OnInit, OnChanges {
  private rolesData: Array<any>;
  languageData: any;

  @Input() units: any[];
  form: FormGroup;
  post: any;
  serverError: Array<string> = new Array();
  edited: boolean = false;
  @Input() visible: boolean;
  @Input() model: any;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave = new EventEmitter();

  constructor(private fb: FormBuilder, private service: DataService, private readonly notify: NotificationService) {
    super();
    this.rolesData = [];
    this.rolesData.push({ name: 'Admin', id: 1, selected: false });
    this.rolesData.push({ name: 'User', id: 2, selected: false });
    this.rolesData.push({ name: 'Translator', id: 4, selected: false });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model != null && this.model.id != undefined) {
      this.edited = false;
      this.service.getUser(this.model.id).pipe(takeUntil(this.destroyed$)).subscribe(u => {
        this.model = u;
        this.initForm();
      });
    }
  }

  ngOnInit() {
    this.service.getLanguages().subscribe(l => {
      this.languageData = l;

      this.initForm();
    });
 
  }

  initForm(): void {
    if (this.model == null) {
      this.model = {};
    }
    this.form = this.fb.group({
      'firstname': [this.model.firstname, Validators.required],
      'lastname': [this.model.lastname, Validators.required],
      'role': [this.model.role, Validators.required],
      'email': [this.model.email, Validators.email],
      'phone': [this.model.phone],
      'companyName': this.model.companyName,
      'companyContact': this.model.companyContact,
      'licenseType': this.model.licenseType,
      'licenseSignedDate': this.getDateOfString(this.model.licenseSignedDate),
      'addisDiscount': this.model.addisDiscount,
      'address': [this.model.address],
      'zip': [this.model.zip],
      'city': [this.model.city],
      'country': [this.model.country],
      'state': [this.model.state],
      'title': [this.model.title],
      'language': [this.model.language, Validators.min(1)],
      'start_date': [this.getDateOfString(this.model.start_date), Validators.required],
      'invoice_date': [this.getDateOfString(this.model.invoice_date), Validators.required],
      'active': [this.model.active, Validators.required],
      'id': [this.model.id],
      'unit': [this.model.unit, Validators.min(1)],
      'formroles': new FormArray([]),
      'formlanguages': new FormArray([])
    });
    this.setLanguage(this.languageData);
    this.setRoles(this.rolesData);
  }

  setRoles(role: any) {
    //this.model.userRoles = [{ id: 2}];
    this.rolesData.map(x => x.selected = false);

    if (this.model.userRoles != undefined && this.model.userRoles != null && this.model.userRoles.length > 0) {
      this.model.userRoles.map(r => {
        var foundRole = this.rolesData.filter(rd => rd.id == r.id)[0];
        if (foundRole) {
          foundRole.selected = true;
        }
      });
    }
    const rolesFGs = this.rolesData.map(r => this.fb.group(r));
    const roleFormArray = this.fb.array(rolesFGs);
    roleFormArray.setValidators(customValidateArray());
    this.form.setControl('formroles', roleFormArray);
  }

  get roles(): FormArray {
    return this.form.get('formroles') as FormArray;
  }

  addRole() {
    this.roles.push(this.fb.group({ name: 'Test', selected: true, id: 999 }));
  }

  setLanguage(language: any) {
    const languagesFGs = this.languageData.map(l => this.fb.group(l));
    const languagesFormArray = this.fb.array(languagesFGs);
    this.form.setControl('formlanguages', languagesFormArray);
  }

  get languages(): FormArray {
    return this.form.get('formlanguages') as FormArray;
  }

  generateNewPassword() {
    //this.service.generateNewPassword(this.model.email).pipe(takeUntil(this.destroyed$)).subscribe((): void => {
    //  this.notify.success('Lösenord genererat');
    //});
    this.service.generateNewPassword(this.model.email).subscribe(resp => {
      this.notify.success('passwordGenerated');

    });
  }

  private getDateOfString(d: string): string {
    if (d == null || d.length < 10)
      return null;

    var date = d.substring(10, 0);
    return date;
  }

  cancel() {
    this.onCancel.emit(false);
  }

  editUser(post: any) {
    this.edited = false;
    this.serverError = new Array();
    this.service.editUser(post).subscribe(response => {
      this.edited = true;
      this.form.markAsUntouched();
      this.onSave.next();
    },
      e => {
        var error = e;
        if (error.ModelState) {
          for (var p in error.ModelState) {
            var array = error.ModelState[p];
            for (var i = 0; i < array.length; i++) {
              this.serverError.push(array[i]);
            }
          }
        }
      });
  }
}
