import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
export function customValidateArray() {
    return function (formArray) {
        var valid = false;
        formArray.controls.forEach(function (x) {
            if (x.value.selected) {
                valid = true;
            }
        });
        return valid ? null : { error: 'Välj minst en roll' };
    };
}
;
var EditUserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EditUserComponent, _super);
    function EditUserComponent(fb, service, notify) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.service = service;
        _this.notify = notify;
        _this.serverError = new Array();
        _this.edited = false;
        _this.onCancel = new EventEmitter();
        _this.onSave = new EventEmitter();
        _this.rolesData = [];
        _this.rolesData.push({ name: 'Admin', id: 1, selected: false });
        _this.rolesData.push({ name: 'User', id: 2, selected: false });
        _this.rolesData.push({ name: 'Translator', id: 4, selected: false });
        return _this;
    }
    EditUserComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.model != null && this.model.id != undefined) {
            this.edited = false;
            this.service.getUser(this.model.id).pipe(takeUntil(this.destroyed$)).subscribe(function (u) {
                _this.model = u;
                _this.initForm();
            });
        }
    };
    EditUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.service.getLanguages().subscribe(function (l) {
            _this.languageData = l;
            _this.initForm();
        });
    };
    EditUserComponent.prototype.initForm = function () {
        if (this.model == null) {
            this.model = {};
        }
        this.form = this.fb.group({
            'firstname': [this.model.firstname, Validators.required],
            'lastname': [this.model.lastname, Validators.required],
            'role': [this.model.role, Validators.required],
            'email': [this.model.email, Validators.email],
            'phone': [this.model.phone],
            'companyName': this.model.companyName,
            'companyContact': this.model.companyContact,
            'licenseType': this.model.licenseType,
            'licenseSignedDate': this.getDateOfString(this.model.licenseSignedDate),
            'addisDiscount': this.model.addisDiscount,
            'address': [this.model.address],
            'zip': [this.model.zip],
            'city': [this.model.city],
            'country': [this.model.country],
            'state': [this.model.state],
            'title': [this.model.title],
            'language': [this.model.language, Validators.min(1)],
            'start_date': [this.getDateOfString(this.model.start_date), Validators.required],
            'invoice_date': [this.getDateOfString(this.model.invoice_date), Validators.required],
            'active': [this.model.active, Validators.required],
            'id': [this.model.id],
            'unit': [this.model.unit, Validators.min(1)],
            'formroles': new FormArray([]),
            'formlanguages': new FormArray([])
        });
        this.setLanguage(this.languageData);
        this.setRoles(this.rolesData);
    };
    EditUserComponent.prototype.setRoles = function (role) {
        var _this = this;
        //this.model.userRoles = [{ id: 2}];
        this.rolesData.map(function (x) { return x.selected = false; });
        if (this.model.userRoles != undefined && this.model.userRoles != null && this.model.userRoles.length > 0) {
            this.model.userRoles.map(function (r) {
                var foundRole = _this.rolesData.filter(function (rd) { return rd.id == r.id; })[0];
                if (foundRole) {
                    foundRole.selected = true;
                }
            });
        }
        var rolesFGs = this.rolesData.map(function (r) { return _this.fb.group(r); });
        var roleFormArray = this.fb.array(rolesFGs);
        roleFormArray.setValidators(customValidateArray());
        this.form.setControl('formroles', roleFormArray);
    };
    Object.defineProperty(EditUserComponent.prototype, "roles", {
        get: function () {
            return this.form.get('formroles');
        },
        enumerable: true,
        configurable: true
    });
    EditUserComponent.prototype.addRole = function () {
        this.roles.push(this.fb.group({ name: 'Test', selected: true, id: 999 }));
    };
    EditUserComponent.prototype.setLanguage = function (language) {
        var _this = this;
        var languagesFGs = this.languageData.map(function (l) { return _this.fb.group(l); });
        var languagesFormArray = this.fb.array(languagesFGs);
        this.form.setControl('formlanguages', languagesFormArray);
    };
    Object.defineProperty(EditUserComponent.prototype, "languages", {
        get: function () {
            return this.form.get('formlanguages');
        },
        enumerable: true,
        configurable: true
    });
    EditUserComponent.prototype.generateNewPassword = function () {
        var _this = this;
        //this.service.generateNewPassword(this.model.email).pipe(takeUntil(this.destroyed$)).subscribe((): void => {
        //  this.notify.success('Lösenord genererat');
        //});
        this.service.generateNewPassword(this.model.email).subscribe(function (resp) {
            _this.notify.success('passwordGenerated');
        });
    };
    EditUserComponent.prototype.getDateOfString = function (d) {
        if (d == null || d.length < 10)
            return null;
        var date = d.substring(10, 0);
        return date;
    };
    EditUserComponent.prototype.cancel = function () {
        this.onCancel.emit(false);
    };
    EditUserComponent.prototype.editUser = function (post) {
        var _this = this;
        this.edited = false;
        this.serverError = new Array();
        this.service.editUser(post).subscribe(function (response) {
            _this.edited = true;
            _this.form.markAsUntouched();
            _this.onSave.next();
        }, function (e) {
            var error = e;
            if (error.ModelState) {
                for (var p in error.ModelState) {
                    var array = error.ModelState[p];
                    for (var i = 0; i < array.length; i++) {
                        _this.serverError.push(array[i]);
                    }
                }
            }
        });
    };
    return EditUserComponent;
}(BaseComponent));
export { EditUserComponent };
