import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  private base = environment.apiUrl //Load from environment.ts
  private token_key: string = "admin_token";
  private token: any;
  private isLoggedIn: boolean;


  constructor(private http: HttpClient, private loginService: LoginService) {
    this.loginService.Stream.subscribe(x => {
      this.isLoggedIn = x.isLoggedIn;
      if (!x.isLoggedIn)
        this.token = null;
    });
    this.loginService.isLoggedIn();

  }

  generateNewPassword(email: string): Observable<any> {
    return this.http.get(`${this.base}/Account/GenerateNewPassword?email=${email}`);
  }

  saveCriterias(criterias: any) {
    return this.http.post(`${this.base}/translation/SaveCriterias`, criterias);
  }

  getCriterias() {
    return this.http.get(`${this.base}/translation/GetCriterias`);
  }

  getCategorys() {
    return this.http.get(`${this.base}/translation/QuestionCategorys`)
  }

  saveCategories(categories: any) {
    return this.http.post(`${this.base}/translation/SaveQuestionCategory`, categories);
  }

  getDrugs(): Observable<any> {
    return this.http.get(`${this.base}/translation/GetDrugs`);
  }

  updateSubstance(data): Observable<any> {
    return this.http.put(`${this.base}/translation/UpdateSubstanceTranslation`, data);
  }

  updateActiveSubstance(data): Observable<any> {
    return this.http.put(`${this.base}/translation/UpdateActiveSubstance`, data);
  }

  moveSubstanceGroup(substanceGroupId: number, drugGroupId: number): Observable<any> {
    return this.http.put(`${this.base}/translation/MoveSubstanceGroup`, { substanceGroupId, drugGroupId });
  }

  addNewSubstance(drugGroupId: number, substanceName: string): Observable<any> {
    return this.http.post(`${this.base}/translation/AddSubstance`, { drugGroupId, substanceName });
  }

  moveTranslation(key: string, culture: number, group: number, newGroup: number): Observable<any> {
    return this.http.put(`${this.base}/translation/movetranslation?newGroupId=${newGroup}`, { key, culture, group });
  }

  moveTranslationGroup(groupId: number, translationGroupKey: string): Observable<any> {
    return this.http.put(`${this.base}/translation/MoveTranslationGroup`, { groupId, translationGroupKey });
  }

  updateTranslation(key: string, culture: number, group: number, text: string): Observable<any> {
    return this.http.put(`${this.base}/translation/updatetranslation`, { key, culture, text, group });
  }

  updateGroup(group): Observable<any> {
    return this.http.put(`${this.base}/translation/UpdateGroup`, group);
  }

  deleteGroup(id: number) {
    return this.http.delete(`${this.base}/translation/deleteGroup?id=${id}`);
  }

  addGroup(name: string): Observable<any> {
    return this.http.post(`${this.base}/translation/addgroup`, { name });
  }

  getGroups(includeTranslations: boolean): Observable<any> {
    return this.http.get(`${this.base}/translation/getgroups?includeTranslations=${includeTranslations}`);
  }

  getGroup(groupId: number): Observable<any> {
    return this.http.get(`${this.base}/translation/getgroup?groupId=${groupId}`);
  }

  getLanguages() {
    return this.http.get(`${this.base}/translation/getLanguages`);
  }

  exportUsers(): Observable<any> {
    return this.http.get(`${this.base}/account/exportusers`);
  }

  exportInterviews(): Observable<any> {
    return this.http.get(`${this.base}/account/exportinterviews`);
  }

  listUsers(): Observable<any> {
    return this.http.get(this.base + '/account/listUsers');
  }

  listUnits(): Observable<any> {
    return this.http.get(this.base + '/account/listUnits');
  }

  listQuestionTypes(): Observable<any> {
    return this.http.get(this.base + '/translation/questiontypes');
  }

  deleteUser(id: number) {
    return this.http.post(this.base + '/account/removeuser', { userId: id });
  }

  createUser(user: any) {
    return this.http.post(this.base + '/account/register', user);
  }

  getQuestions(): Observable<any> {
    return this.http.get(this.base + '/question');
  }

  createInterview(interview: any): Observable<any> {
    return this.http.post(this.base + '/interview/create', interview);
  }

  createUnit(name: string): Observable<any> {
    return this.http.get(this.base + '/account/addunit?name=' + name);
  }

  deleteUnit(unitId: number): Observable<any> {
    return this.http.get(this.base + '/account/removeunit?id=' + unitId);
  }

  editUser(user): Observable<any> {
    return this.http.post(this.base + '/account/editUser', user);
  }

  getUser(id): Observable<any> {
    return this.http.get(this.base + '/account/getUser?id=' + id);
  }

  updateUnit(unit): Observable<any> {
    return this.http.post(this.base + '/account/EditUnit', unit);
  }

  saveQuestionTypeTranslations(data: any): Observable<any> {
    return this.http.post(this.base + '/translation/saveQuestionTypeTranslations', data);
  }

  listQuestionTranslations(): Observable<any> {
    return this.http.get(this.base + '/translation/questions');
  }

  saveQuestionTranslations(data: any[]): Observable<any> {
    return this.http.post(this.base + '/translation/saveQuestionTranslations', data);
  }

  getCodeBackgrounds(): Observable<any> {
    return this.http.get(this.base + '/translation/GetCodeBackgrounds');
  }

  saveCodeBackgrounds(data: any[]) {
    return this.http.post(this.base + '/translation/SaveCodeBackgrounds', data);
  }

  generateRegLink(): Observable<any> {
    return this.http.get(this.base + '/account/GenerateRegistrationLink');
  }

  getTotalInterviews(): Observable<any> {
    return this.http.get(this.base + '/interview/GetTotalInterviews');
  }

  getInterviewsLastMonth(): Observable<any> {
    return this.http.get(this.base + '/interview/GetInterviewsLastMonth');
  }

  getUserCount(): Observable<any> {
    return this.http.get(this.base + '/account/GetUserCount');
  }

  getInterviewsCount(): Observable<any> {
    return this.http.get(this.base + '/interview/GetInterviewsCount');
  }


}
