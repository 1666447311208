import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service.service';
import { NotificationService } from 'src/app/services/notification.service';

declare var $: any;

@Component({
  selector: 'app-translate-question-category',
  templateUrl: './translate-question-category.component.html',
  styleUrls: ['./translate-question-category.component.css']
})
export class TranslateQuestionCategoryComponent implements OnInit {
  categories: any;
  isLoading = true;
  updating = false;

  constructor(private readonly service: DataService, private readonly notify: NotificationService) { }

  ngOnInit() {
    this.load();
    $(window).scroll(function () {
      //$('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
      $('#appButton').css({ top: $(this).scrollTop() });
    });
  }

  load() {
    this.service.getCategorys().subscribe(categories => {
      this.categories = categories;
      console.log(categories);
      this.isLoading = false;
    });
  }

  save() {
    this.updating = true;
    this.service.saveCategories(this.categories).subscribe(() => {
      this.updating = false;
      this.notify.success('translationUpdated');
      this.load();
    });
  }

}
