
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService {

  constructor(private readonly router: Router) { }

  canActivate() {
    return new Observable<boolean>(observer => {
      if (localStorage.admin_token) {
        observer.next(true);
      }
      else {
        observer.next(false);
        this.router.navigateByUrl('/login');
      }
    });
  }
}
