import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../../components/base-component';
import { DataService } from '../../../../services/data-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
var GroupItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GroupItemComponent, _super);
    function GroupItemComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.onDelete = new EventEmitter();
        _this.onUpdate = new EventEmitter();
        _this.inEdit = false;
        return _this;
    }
    GroupItemComponent.prototype.deleteGroup = function () {
        var _this = this;
        this.service.deleteGroup(this.group.Id).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.onDelete.emit();
            _this.notify.success('groupDeleted');
        });
    };
    GroupItemComponent.prototype.updateGroup = function () {
        var _this = this;
        this.service.updateGroup(this.group).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.inEdit = false;
            _this.onUpdate.emit();
            _this.notify.success('groupUpdated');
        });
    };
    GroupItemComponent.prototype.toggleInEdit = function () {
        this.inEdit = !this.inEdit;
    };
    return GroupItemComponent;
}(BaseComponent));
export { GroupItemComponent };
