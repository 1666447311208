<th scope="row">{{index+1}}</th>
<td *ngIf="!inEdit">{{group.Name}}</td>
<td *ngIf="inEdit">
  <input type="text" class="form-control" [(ngModel)]="group.Name" #name="ngModel" aria-label="Gruppnamn" />
</td>
<td *ngIf="!inEdit" class="group-buttons" [ngClass]="{'no-top': !index}">
  <button class="btn btn-sm btn-info mx-2" (click)="toggleInEdit()">Edit</button>
  <app-button [color]="'danger btn-sm'" [debug]="false" [text]="'Delete'" [useConfirm]="true" (click)="deleteGroup()">Delete</app-button>
</td>
<td *ngIf="inEdit" class="group-buttons float-right" [ngClass]="{'no-top': !index}">
  <button class="btn btn-success mx-2" (click)="updateGroup()">Spara</button>
</td>
