<div *ngIf="visible">
  <h3>Skapa ny enhet</h3>

  <table class="table" style="width: 100%;">
    <thead>
    </thead>
    <tbody>
      <tr>
        <td>
          <strong>Namn</strong>
        </td>
        <td>
          <input [(ngModel)]="model.name" type="text" class="form-control" />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <app-button [disabled]="model.name == null || model.name === ''" [text]="'Skapa'" [debug]="false" [useSpinner]="false" [showSpinner]="addingUnit" [color]="'success'" [right]="true" (click)="save()"></app-button>
          <app-button [text]="'Avbryt'" [debug]="false" [useSpinner]="false" [right]="true" (click)="cancel()"></app-button>
          <div *ngIf="addUnitSuccess" class="alert green">Enheten är nu skapad</div>
          <div *ngIf="error.hasError" class="alert">Det gick inte att skapa enheten</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
