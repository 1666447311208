<div *ngIf="visible" style="margin-bottom: 30px;">
  <h3>Ändra användare</h3>
  <form [formGroup]="form" (ngSubmit)="editUser(form.value)">
    <table class="table" style="width: 100%;">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Förnamn *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['firstname'].valid && form.controls['firstname'].touched">Förnamn är ett obligatoriskt fält</div>
            <input type="text" class="form-control" formControlName="firstname" />
          </td>
          <td>
            <strong>Efternamn *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['lastname'].valid && form.controls['lastname'].touched">Efternamn är ett obligatoriskt fält</div>
            <input type="text" class="form-control" formControlName="lastname" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Roll</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['role'].valid && form.controls['role'].touched">Roll är ett obligatoriskt fält</div>

            <div formArrayName="formroles">

              <div *ngFor="let r of roles?.controls; let i=index" [formGroupName]="i">
                <input type="checkbox" formControlName="selected" />
                {{r.value.name}}
              </div>
            </div>

          </td>
          <td>
            <strong>Email *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['email'].valid && form.controls['email'].touched">E-post är ett obligatoriskt fält</div>
            <input type="text" class="form-control" formControlName="email" />
          </td>
        </tr>
       
        <tr>
          <td>
            <strong>Titel</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="title" />
          </td>
          <td>
            <strong>Språk *</strong>
          </td>
          <td>
            <!--<select class="form-control" formControlName="language">
              <option [value]="-1">Välj språk</option>
              <option [value]="1">Svenska</option>
              <option [value]="2">Norska</option>
              <option [value]="3">Engelska</option>
            </select>-->

            <div class="alert" *ngIf="!form.controls['language'].valid && form.controls['language'].touched">Språk är ett obligatoriskt fält</div>
            <select class="form-control" formControlName="language">
              <option *ngFor="let l of languages?.controls; let i=index" [value]="l.value.laId">{{l.value.laName}}</option>
            </select>
          </td>
        </tr>
        
        <tr>
          <td>
            <strong>Aktiv</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['active'].valid && form.controls['active'].touched">Aktiv är ett obligatoriskt fält</div>
            <input type="checkbox" formControlName="active" />
            <p style="font-size: 14px;">Om en användare går från icke-aktiv till<br/> aktiv kommer det alltid skickas ut<br/> ett aktiveringsmejl till användaren</p>
          </td>
          <td>
            <strong>Enhet *</strong>
          </td>
          <td>
            <select class="form-control" formControlName="unit">
              <option [value]="-1">Välj enhet</option>
              <option [value]="u.id" *ngFor="let u of units">{{u.name}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <button type="button" class="btn btn-warning float-right btn-margin" (click)="generateNewPassword()">Generera nytt lösenord</button>
          </td>
        </tr>

        <h3 style="margin-top: 30px;">Licensuppgifter</h3>
        <tr>
          <td>
            <strong>Företag</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="companyName" />
          </td>

          <td>
            <strong>Företags kontakt</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="companyContact" />
          </td>
        </tr>

        <tr>
          <td>
            <strong>Telefon</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="phone" />
          </td>
          <td>
            <strong>Adress</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="address" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Postnr</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="zip" />
          </td>
          <td>
            <strong>Ort</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="city" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Land</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="country" />
          </td>
          <td>
            <strong>State</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="state" />
          </td>
        </tr>

        <tr>
          <td>
            <strong>License typ</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="licenseType" />
          </td>
          <td>
            <strong>License signerat</strong>
          </td>
          <td>
            <input type="date" readonly class="form-control" formControlName="licenseSignedDate" />
          </td>
        </tr>

        <tr>
          <td>
            <strong>Addis rabatt</strong>
          </td>
          <td>
            <!-- <input type="text" class="form-control" formControlName="addisDiscount" /> -->

            <!-- <select class="form-control" formControlName="addisDiscount">
              <option [value]="1">Ja</option>
              <option [value]="0">Nej</option>
            </select> -->

            <div class="form-check">
              <input class="form-check-input" formControlName="addisDiscount" type="checkbox" id="flexCheckDefault">
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <strong>Startdatum *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['start_date'].valid && form.controls['start_date'].touched">Startdatum är ett obligatoriskt fält</div>
            <input type="date" class="form-control" formControlName="start_date" />
          </td>
          <td>
            <strong>Fakturering *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['invoice_date'].valid && form.controls['invoice_date'].touched">Faktureringsdatum är ett obligatoriskt fält</div>
            <input type="date" class="form-control" formControlName="invoice_date" />
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <button type="submit" class="btn btn-success pull-right btn-margin" [disabled]="!form.valid">Spara</button>
            <app-button [text]="'Stäng'" [useSpinner]="false" [useConfirm]="form.touched" [confirmText]="'Vill du fortsätta utan att spara?'" [right]="true" (onClick)="cancel()" [debug]="false"></app-button>
          </td>
        </tr>
        <tr *ngIf="serverError.length > 0">
          <td colspan="4">
            <h3>Det gick inte att ändra användaren</h3>
            <div class="alert" style="display: block;">
              <ul>
                <li *ngFor="let e of serverError">{{e}}</li>
              </ul>
            </div>
          </td>
        </tr>
        <tr *ngIf="edited">
          <td colspan="4">
            <h3 class="alert green float-right">Användaren är ändrad</h3>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

</div>
