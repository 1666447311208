import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';

export function customValidateArray(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    let valid: boolean = false;

    formArray.controls.forEach((x: FormControl) => {
      if (x.value.selected) {
        valid = true;
      }
    })
    return valid ? null : { error: 'Välj minst en roll' }
  }
};

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})

export class AddUserComponent extends BaseComponent {
  private rolesData: Array<any>;
  languageData: any;

  form: FormGroup;
  post: any;
  serverError: Array<string> = new Array();
  created: boolean = false;
  @Input() visible: boolean;
  @Input() units: any[];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter();

  constructor(private fb: FormBuilder, private service: DataService, private readonly notify: NotificationService) {
    super();
    this.rolesData = [];
    this.rolesData.push({ name: 'Admin', id: 1, selected: false });
    this.rolesData.push({ name: 'User', id: 2, selected: false });
    this.rolesData.push({ name: 'Translator', id: 4, selected: false });

    this.service.getLanguages().subscribe(l => {
      this.languageData = l;

      this.initForm();
    });
    
  }

  initForm(): void {
    this.form = this.fb.group({
      'firstname': [null, Validators.required],
      'lastname': [null, Validators.required],
      'role': [-1],
      'email': [null, Validators.email],
      'phone': [null],
      'address': [null],
      'zip': [null],
      'city': [null],
      'title': [null],
      'language': [null, Validators.required],
      'formlanguages': new FormArray([]),
      'start_date': [null, Validators.required],
      'invoice_date': [null, Validators.required],
      'active': [false, Validators.required],
      'unit': [-1, Validators.min(1)],
      'formroles': new FormArray([])
    });
    this.setRoles(this.rolesData);
    this.setLanguage(this.languageData);
  }

  cancel() {
    this.onCancel.emit();
  }

  setRoles(role: any) {
    this.rolesData.map(x => x.selected = false);

    const rolesFGs = this.rolesData.map(r => this.fb.group(r));
    const roleFormArray = this.fb.array(rolesFGs);
    roleFormArray.setValidators(customValidateArray());
    this.form.setControl('formroles', roleFormArray);
  }

  get roles(): FormArray {
    return this.form.get('formroles') as FormArray;
  }

  setLanguage(language: any) {
    const languagesFGs = this.languageData.map(l => this.fb.group(l));
    const languagesFormArray = this.fb.array(languagesFGs);
    this.form.setControl('formlanguages', languagesFormArray);
  }

  get languages(): FormArray {
    return this.form.get('formlanguages') as FormArray;
  }

  addUser(post: any) {
    this.created = false;
    this.serverError = new Array();
    this.service.createUser(post).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.notify.success('userCreated');
      this.onSave.emit();
    },
      e => {
        var error = e;
        if (error.ModelState) {
          for (var p in error.ModelState) {
            var array = error.ModelState[p];
            for (var i = 0; i < array.length; i++) {
              this.serverError.push(array[i]);
            }
          }
        }
      });
  }
}
