import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
var AddUnitComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddUnitComponent, _super);
    function AddUnitComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.onCreated = new EventEmitter();
        _this.onCancel = new EventEmitter();
        _this.addingUnit = false;
        _this.addUnitSuccess = false;
        _this.model = {};
        _this.error = { message: 'Namn måste anges', hasError: false };
        return _this;
    }
    AddUnitComponent.prototype.save = function () {
        var _this = this;
        this.addingUnit = true;
        if (this.model == null || this.model.name == undefined || this.model.name.length == 0) {
            this.addingUnit = false;
        }
        else {
            this.service.createUnit(this.model.name).subscribe(function (x) {
                _this.error.hasError = false;
                _this.addingUnit = false;
                _this.model.name = '';
                var returndata = x;
                _this.onCreated.emit(returndata);
                _this.notify.success('unitCreated');
            }, function (e) {
                _this.error.hasError = true;
                _this.addingUnit = false;
                _this.addUnitSuccess = false;
            });
        }
    };
    AddUnitComponent.prototype.cancel = function () {
        this.model.name = '';
        this.addingUnit = false;
        this.addUnitSuccess = false;
        this.error.hasError = false;
        this.onCancel.emit();
    };
    return AddUnitComponent;
}(BaseComponent));
export { AddUnitComponent };
