/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../../components/button/button.component.ngfactory";
import * as i5 from "../../../../components/button/button.component";
import * as i6 from "./group-item.component";
import * as i7 from "../../../../services/data-service.service";
import * as i8 from "../../../../services/notification.service";
var styles_GroupItemComponent = [i0.styles];
var RenderType_GroupItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupItemComponent, data: {} });
export { RenderType_GroupItemComponent as RenderType_GroupItemComponent };
function View_GroupItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.group.Name; _ck(_v, 1, 0, currVal_0); }); }
function View_GroupItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["aria-label", "Gruppnamn"], ["class", "form-control"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.group.Name = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, [["name", 4]], 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.group.Name; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_GroupItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "td", [["class", "group-buttons"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "no-top": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-sm btn-info mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleInEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(6, 4308992, null, 0, i5.ButtonComponent, [], { text: [0, "text"], color: [1, "color"], useConfirm: [2, "useConfirm"], debug: [3, "debug"] }, null), (_l()(), i1.ɵted(-1, null, ["Delete"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group-buttons"; var currVal_1 = _ck(_v, 2, 0, !_co.index); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "Delete"; var currVal_3 = "danger btn-sm"; var currVal_4 = true; var currVal_5 = false; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_GroupItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "td", [["class", "group-buttons float-right"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "no-top": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-success mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Spara"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group-buttons float-right"; var currVal_1 = _ck(_v, 2, 0, !_co.index); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GroupItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [["scope", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupItemComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupItemComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupItemComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupItemComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.inEdit; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.inEdit; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.inEdit; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.inEdit; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.index + 1); _ck(_v, 1, 0, currVal_0); }); }
export function View_GroupItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-item", [], null, null, null, View_GroupItemComponent_0, RenderType_GroupItemComponent)), i1.ɵdid(1, 180224, null, 0, i6.GroupItemComponent, [i7.DataService, i8.NotificationService], null, null)], null, null); }
var GroupItemComponentNgFactory = i1.ɵccf("app-group-item", i6.GroupItemComponent, View_GroupItemComponent_Host_0, { group: "group", index: "index" }, { onDelete: "onDelete", onUpdate: "onUpdate" }, []);
export { GroupItemComponentNgFactory as GroupItemComponentNgFactory };
