import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../../../services/data-service.service';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-substance-item',
  templateUrl: './substance-item.component.html',
  styleUrls: ['./substance-item.component.css']
})
export class SubstanceItemComponent implements OnInit {
  @Input() lang: any;
  @Input() drugGroup: any;
  @Input() substanceGroup: any;
  @Input() selectedGroup: number;
  @Input() index: number;
  @Output() onChange = new EventEmitter();
  inEdit = false;

  constructor(private service: DataService, private readonly notify: NotificationService) { }

  ngOnInit() {
  }

  updateTranslation() {
    this.service.updateSubstance(this.lang)
      .subscribe(response => {
        this.inEdit = false;
        this.onChange.emit();
        this.notify.success('translationUpdated');
      });
  }

 
  toggleInEdit() {
    this.inEdit = !this.inEdit;
  }



}
