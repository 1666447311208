<div *ngIf="!isLoading">
  <h2 class="text-center">Page texts</h2>
  <hr />
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <label for="groups" class="col-sm-2 col-form-label">Group:</label>
        <div class="col-sm-10">
          <select class="form-control" id="groups" [(ngModel)]="selectedGroup" (ngModelChange)="onGroupChanged()">
            <option *ngFor="let group of groups" [value]="group.Id">{{group.Name}}</option>
          </select>
        </div>
      </div>
    
    </div>
  </div>
 
  <div class="row spacer">
    <div class="col-lg-12">
      <p>You can only edit and translate the language you entered into your usersettings.</p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" style="padding: 35px;"></th>
            <th scope="col" class="w-25">Language</th>
            <th scope="col" class="w-25">Key</th>
            <th scope="col" class="w-50">Text</th>

            <th scope="col"></th>
          </tr>
        </thead>

        <ng-container *ngFor="let translationGroup of translationGroups; let i = index">
          <tbody [ngClass]="{ 'block' : (i + 1) % 2 == 0 }" class="block-two">
            <tr>
             <td colspan="4">
               
             </td>
              <td colspan="1">
                <div class="dropdown">
                  <button class="btn btn-secondary btn-move btn-sm dropdown-toggle" [hidden]="!translationGroup.isAdmin" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Move to
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a *ngFor="let group of groups" class="dropdown-item" (click)="moveTranslationGroup(group.Id, translationGroup)">{{group.Name}}</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngFor="let lang of translationGroup.translationLanguages">
              <td></td>
              <td>{{lang.Culture.Name}}</td>
              <app-translate-label-item [selectedGroup]="selectedGroup" [groups]="groups" [translationGroup]="translationGroup" [lang]="lang" [index]="i" (onChange)="loadTranslations()"></app-translate-label-item>
            </tr>
          </tbody>
        </ng-container>


        <!--<tbody>
          <ng-container *ngFor="let translation of translationGroups; let i = index">
            <tr>
              <app-translate-label-item [selectedGroup]="selectedGroup" [groups]="groups" [translation]="translation" [index]="i" (onChange)="loadTranslations()"></app-translate-label-item>
            </tr>
          </ng-container>
        </tbody>-->

      </table>
    </div>
  </div>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
