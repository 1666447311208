import * as tslib_1 from "tslib";
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
var BackgroundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BackgroundComponent, _super);
    function BackgroundComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.isLoading = true;
        _this.updating = false;
        _this.load();
        return _this;
    }
    BackgroundComponent.prototype.ngOnInit = function () {
    };
    BackgroundComponent.prototype.load = function () {
        var _this = this;
        this.service.getCodeBackgrounds().subscribe(function (response) {
            _this.isLoading = false;
            _this.codes = response;
            console.log('codes', _this.codes);
            $(window).scroll(function () {
                $('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
            });
        });
    };
    BackgroundComponent.prototype.save = function () {
        var _this = this;
        this.updating = true;
        var array = [];
        this.codes.map(function (d) { return Array.prototype.push.apply(array, d.CodeBackgrounds); });
        console.log('array', array);
        this.service.saveCodeBackgrounds(array).subscribe(function (response) {
            _this.load();
            _this.updating = false;
            _this.notify.success('translationUpdated');
        });
    };
    return BackgroundComponent;
}(BaseComponent));
export { BackgroundComponent };
