import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataService } from '../../services/data-service.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  env: any = environment;
  testNav: boolean = false;
  loginOrOut: string;
  isLoggedIn: boolean;
  userRoleAuth: boolean;
  userRoles: any;

  constructor(private router: Router, private loginService: LoginService, private dataService: DataService) {
    this.loginService.checkValidity();

    if(this.env.test == true) {
      this.testNav = true;
    }

    this.loginService.Stream.subscribe((data: any) => {
      if (data.isLoggedIn) {
        this.isLoggedIn = true;
        this.loginOrOut = data.text;

        this.userRoles = data.Roles[0];
        if (this.userRoles.includes('Admin')) {
          this.userRoleAuth = true;
        } else {
          this.userRoleAuth = false;
        }

      } else {
        this.isLoggedIn = false;
        this.loginOrOut = data.text;
      }
    });
    this.loginService.isLoggedIn();

  }


  logInOut() {
    if (this.isLoggedIn) {
      this.logout();
    } else {
      this.login();
    }
  }

  logout() {
    this.loginService.logout();
    this.login();
  }

  login() {
    this.router.navigate(['login']);
  }

  ngOnInit() {

  }

}
