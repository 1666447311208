import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.css']
})
export class AddUnitComponent extends BaseComponent {
  @Input() visible: boolean;
  @Output() onCreated = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  model: any;
  error: any;
  loading: boolean;
  addingUnit: boolean = false;
  addUnitSuccess: boolean = false;

  constructor(private service: DataService, private readonly notify: NotificationService) {
    super();
    this.model = {};
    this.error = { message: 'Namn måste anges', hasError: false };
  }

  save() {
    this.addingUnit = true;
    if (this.model == null || this.model.name == undefined || this.model.name.length == 0) {
      this.addingUnit = false;
    } else {
      this.service.createUnit(this.model.name).subscribe(x => {
        this.error.hasError = false;
        this.addingUnit = false;
        this.model.name = '';
        var returndata = x;
        this.onCreated.emit(returndata);
        this.notify.success('unitCreated');
      },
        e => {
          this.error.hasError = true;
          this.addingUnit = false;
          this.addUnitSuccess = false;
        });
    }
  }

  cancel() {
    this.model.name = '';
    this.addingUnit = false;
    this.addUnitSuccess = false;
    this.error.hasError = false;
    this.onCancel.emit();
  }
}
