import { Component, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { BaseComponent } from '../base-component';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends BaseComponent {
  @Input() visible: boolean;
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  id: string;
  psw: string;
  isLoading = false;
  error = false;


  constructor(private readonly service: LoginService, private readonly router: Router, private notify: NotificationService) {
    super();
  }

  cancel() {
    this.onCancel.emit();
  }

  changePassword() {
    this.isLoading = true;
    this.service.changePassword({ NewPassword: this.psw, UserId: this.id }).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      //this.router.navigateByUrl('/login');
      this.notify.success('passwordUpdated');
      this.onSave.emit();
    }, error => {
      this.error = true;
      console.log(error)
      this.isLoading = false;
    });
  }
}
