import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent extends BaseComponent {
  groups: { Id: number, Key: string, Name: string }[];
  name: string;
  isLoading = true;

  constructor(private readonly service: DataService, private readonly notify: NotificationService) {
    super();
    this.loadGroups();
  }

  private loadGroups() {
    this.service.getGroups(false).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.groups = response;
      this.isLoading = false;
    });
  }

  addGroup() {
    this.service.addGroup(this.name).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.name = '';
      this.loadGroups();
      this.notify.success('groupAdded');
    });
  }
}
