import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-translate-question',
  templateUrl: './translate-question.component.html',
  styleUrls: ['./translate-question.component.css']
})
export class TranslateQuestionComponent extends BaseComponent {
  selectedQuestion: number;
  questions: any[];
  cultureQuestions: Array<any> = new Array<any>();
  updating: boolean;
  success = false;
  isLoading = true;

  constructor(private service: DataService, private readonly notify: NotificationService) {
    super();
    this.load();
  }

  load() {
    this.service.listQuestionTranslations().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      console.log('translation question', response);
      this.questions = response;
      this.isLoading = false;
      $(window).scroll(function () {
        $('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
      });
    });
  }

  saveTranslation() {
    this.updating = true;
    var array = [];
    this.questions.map(d => Array.prototype.push.apply(array, d.Items));
    console.log('array', array);
    this.service.saveQuestionTranslations(array).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.load();
      this.updating = false;
      this.notify.success('translationUpdated');
    });
  }
}
