import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';

declare var $: any;

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.css']
})
export class BackgroundComponent extends BaseComponent {

  codes: any[];
  isLoading: boolean = true;
  updating: boolean = false;

  constructor(private service: DataService, private readonly notify: NotificationService) {
    super();
    this.load();
  }

  ngOnInit() {
  }

  load() {
    this.service.getCodeBackgrounds().subscribe(response => {
      this.isLoading = false;
      this.codes = response;
      console.log('codes', this.codes);

      $(window).scroll(function () {
        $('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
      });
    });
  }

  save() {
    this.updating = true;
    var array = [];
    this.codes.map(d => Array.prototype.push.apply(array, d.CodeBackgrounds));
    console.log('array', array);
    this.service.saveCodeBackgrounds(array).subscribe(response => {
      this.load();
      this.updating = false;
      this.notify.success('translationUpdated');
    });
  }
}
