<div *ngIf="!isLoading">
  <div class="row spacer">
    <div class="col-lg-6">
      <div class="input-group mb-3">
        <input [(ngModel)]="name" #Name="ngModel" id="group" type="text" class="form-control" placeholder="Groupname" aria-label="Groupname" aria-describedby="basic-addon2" required>
        <div class="input-group-append">
          <button class="btn btn-success" type="button" [disabled]="name == null || name === ''" (click)="addGroup()">Add</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th class="col">Existing groups</th>
            <th class="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of groups; let i = index">
            <app-group-item [group]="group" [index]="i" (onDelete)="loadGroups()" (onUpdate)="loadGroups()"></app-group-item>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
