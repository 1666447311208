<div class="col-md-6 mx-auto" *ngIf="!isLoading">
  <div class="card-body rounded-0">
    <div class="card-header">
      <h3 class="mb-0">Log in</h3>
    </div>
    <div class="card-body">
      <form class="form" role="form" autocomplete="on" id="formLogin" method="POST">
        <div class="form-group">
          <input type="email" class="form-control form-control-lg rounded-0" [(ngModel)]="usr" name="usr" placeholder="Email address">
        </div>
        <div class="form-group">
          <input type="password" class="form-control form-control-lg rounded-0" [(ngModel)]="psw" name="psw" placeholder="Password">
        </div>
        <app-button [debug]="false" [text]="'Log in'" [color]="'info'" (onClick)="login()"></app-button>
        <!--<div class="float-right">
          <a class="center" routerLink="/reset" routerLinkActive="active">Ändra lösenord</a>
        </div>-->
      </form>
    </div>
  </div>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
