/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./error.component";
import * as i4 from "@angular/material/snack-bar";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
export function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data.message)); _ck(_v, 2, 0, currVal_0); }); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorComponent, [i4.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var ErrorComponentNgFactory = i1.ɵccf("app-error", i3.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
