import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent extends BaseComponent {
  users: Array<any>;
  filteredUsers: any[];
  units: any[];
  selectedUnit: number;
  messages = new Array<{ Id: number, Text: string, Active: boolean }>();
  createUserVisible: boolean;
  createUnitVisible: boolean;
  createMessageVisible = false;
  editUserVisible: boolean;
  resetPasswordVisible: boolean;
  user: any;
  isLoading = true;
  updating = false;
  userSortOrderAsc: boolean = true;
  noMatch: boolean = false;

  userSearchCtrl = new FormControl();
  
  constructor(private service: DataService, private readonly notify: NotificationService) {
    super();
    this.user = null;
    this.users = new Array();
    this.units = new Array();
    this.loadUnits(true);
  }

  private loadUnits(loadUsers: boolean): void {
    this.createUnitVisible = false;
    this.service.listUnits().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      let units = response;
      this.units = new Array();
      for (var i = 0; i < units.length; i++) {
        this.units.push({ name: units[i].uName, id: units[i].uId });
      }
      this.sortUnitsByName();

      // this.selectedUnit = this.units[0].id;
      this.selectedUnit = -1;
      if (loadUsers)
        this.loadUsers(false);
    });
  }

  public sortUserBy(column: string): void {
    switch (column) {
      case "name":
        this.sortUsersByName();
        break;
      case "email":
        //TODO:
        break;
      case "active":
        //TODO:
        break;
      case "roles":
        //TODO:
        break;
      default:
        break;
    }

  }
  private sortUsersByName() {
    this.filteredUsers.sort((left, right): number => {


      if (!this.userSortOrderAsc) {
        if (left.name.toLowerCase() > right.name.toLowerCase())
          return -1;
        else if (left.name.toLowerCase() < right.name.toLowerCase())
          return 1;
        else
          return 0;
      } else {
        if (left.name.toLowerCase() < right.name.toLowerCase())
          return -1;
        else if (left.name.toLowerCase() > right.name.toLowerCase())
          return 1;
        else
          return 0;
      }
    });
    //After sorting, switch the sort order
    this.userSortOrderAsc = !this.userSortOrderAsc;
  }
  private sortUnitsByName(): void {
    this.units.sort((left, right): number => {

      if (left.name.toLowerCase() < right.name.toLowerCase())
        return -1;
      else if (left.name.toLowerCase() > right.name.toLowerCase())
        return 1;
      else
        return 0;

    });
  }

  private loadUsers(loadUnits: boolean): void {
    this.editUserVisible = false;
    this.createUserVisible = false;
    this.service.listUsers().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      var users = response;
      this.users = new Array();
      for (var i = 0; i < users.length; i++) {
        this.users.push({
          id: users[i].id,
          name: users[i].firstname,
          email: users[i].email,
          active: users[i].active,
          roles: users[i].userRoles,
          unit: users[i].unit
        });
      }
      this.filterUsers();
      this.isLoading = false;
      this.sortUsersByName();
      if (loadUnits)
        this.loadUnits(false);
    });
  }

  filterUsers() {
    this.userSortOrderAsc = true;
    this.filteredUsers = this.users.filter(u => u.unit == this.selectedUnit);
    this.sortUsersByName();
  }

  searchUsers() {
    let searchWord = this.userSearchCtrl.value.toLowerCase();
    let resultList: any[] = []; 

    if(searchWord) {
      // result = this.getUsersByName(searchWord);
      this.users.forEach(u => {

        if(u != null) {
          if(u.name != null) {
            if(u.name.toLowerCase().includes(searchWord)) {
              resultList.push(u);
            }
          }

          if(resultList.indexOf(u) == -1 && u.email != null) {
            if(u.email.toLowerCase().includes(searchWord)) {
              resultList.push(u);
            }
          }
        }
      });

      this.filteredUsers = resultList.sort((a, b) => (a.name < b.name) ? 1 : ((a.name > b.name) ? -1 : 0));

      if(this.filteredUsers.length <= 0) {
        this.noMatch = true;
      } 
      else {
        this.noMatch = false;
      }
    }

    console.log(this.filteredUsers);
  }

  clear(ctrl: AbstractControl) {
    ctrl.setValue('');
    ctrl.markAsDirty();
    this.noMatch = false;
  }

  toggleCreateMessage() {
    this.createMessageVisible = !this.createMessageVisible;
  }

  toggleEditMessage(message: any) {
    message.inEdit = !message.inEdit;
  }

  exportUsers() {
    this.service.exportUsers().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      var users = new Array<string>('Namn;Epost;Enhet;Licensstart;Adress;Stad;Postnummer;Telefon;Yrkestitel');
      (response as string[]).forEach(user => users.push(user));
      this.downloadCSV(users, 'Users.csv');
    });
  }

  exportInterviews() {
    this.service.exportInterviews().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      var interviews = new Array<string>('Skapad;Avslutad;Uno-kod;Namn;Status');
      (response as string[]).forEach(interview => interviews.push(interview));
      this.downloadCSV(interviews, 'Interviews.csv');
    });
  }

  downloadCSV(csv: string[], filename: string) {
    let link = document.createElement('a');
    link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv.join('\n'))}`);
    link.setAttribute('download', filename);
    link.click();
  }

  editUser(user: any) {
    window.scrollTo(0, 0);
    this.user = user;
    this.toggleEditUser();
  }

  editUnit(unit: any) {
    unit.editingUnit = !unit.editingUnit;
  }

  toggleResetPassword() {
    this.resetPasswordVisible = !this.resetPasswordVisible;
    this.createUnitVisible = false;
    this.createUserVisible = false;
    this.editUserVisible = false;
  }

  toggleCreateUnit() {
    this.createUnitVisible = !this.createUnitVisible;
    this.createUserVisible = false;
    this.editUserVisible = false;
    this.resetPasswordVisible = false;
  }

  toggleCreateUser() {
    this.createUserVisible = !this.createUserVisible;
    this.createUnitVisible = false;
    this.editUserVisible = false;
    this.resetPasswordVisible = false;
  }

  cancelEdit() {
    this.createUserVisible = false;
    this.createUnitVisible = false;
    this.editUserVisible = false;
    this.createMessageVisible = false;
    this.resetPasswordVisible = false;
  }

  toggleEditUser() {
    this.createUserVisible = false;
    this.createUnitVisible = false;
    this.editUserVisible = true;
    this.resetPasswordVisible = false;
  }

  deleteUser(user: any) {
    if(window.confirm('Användarens konto samt intervjuer, svar och kommentarer kopplade till kontot kommer att raderas. Är du säker på att du vill ta bort den här användaren?')) {
      user.spinnerDeleteUser = true;
      this.service.deleteUser(user.id).pipe(takeUntil(this.destroyed$)).subscribe(x => {
        user.spinnerDeleteUser = false;
        this.loadUsers(false);
        this.notify.success('userDeleted');
      });
    }
  }

  deleteUnit(unit: any) {
    this.service.deleteUnit(unit.id).pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.loadUnits(true);
      this.notify.success('unitDeleted');
    });
  }

  onUnitCreated(unit: any) {
    this.units.push({ id: unit.uId, name: unit.uName });
  }

  saveUnit(unit: any) {
    this.updating = true;
    this.service.updateUnit(unit).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      unit.editingUnit = false;
      this.updating = false;
      this.notify.success('unitUpdated');
    });
  }
}
