import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private base = environment.apiUrl //Load from environment.ts
  private tokenKey: string = "admin_token";
  private baseLogin = `${environment.apiUrl}/token`; //Load from environment.ts
  Stream: CustomEventEmitter;

  constructor(private http: HttpClient,
    private router: Router) {
    this.Stream = new CustomEventEmitter();
  }

  login(usr: string, psw: string): Observable<boolean> {
    return new Observable(observer => {
      var data = `userName=${encodeURIComponent(usr)}&password=${encodeURIComponent(psw)}&grant_type=password`;

      this.http.post(this.baseLogin, data).subscribe(response => {
        var tokenObject = response as any;
        localStorage.setItem(this.tokenKey, JSON.stringify(tokenObject));
        this.getActiveUser().subscribe(roles => {
          tokenObject.roles = roles;
          localStorage.setItem(this.tokenKey, JSON.stringify(tokenObject));
          this.emitStream(tokenObject);
          observer.next(true);
          observer.complete();
        })
        
      }, error => {
        observer.next(false)
        observer.complete();
      });

    });
  }

  logout() {
    localStorage.removeItem(this.tokenKey);
    this.Stream.emit({ isLoggedIn: false, text: 'Log in' });
    this.router.navigate(['/login']);
  }

  isLoggedIn() {
    var token = localStorage.getItem(this.tokenKey);
    if (token != undefined) {
      var tokenObject = JSON.parse(token);
      this.emitStream(tokenObject);
    } else {
      this.Stream.emit({ isLoggedIn: false, text: 'Log in' });
    }
  }

  checkValidity() {
    var token = localStorage.getItem(this.tokenKey);

    if (token != undefined) {
      var tokenObject = JSON.parse(token);
      
      var current = new Date();
      var tokenExp = new Date(tokenObject[".expires"]);

      console.log('current date:', current);
      console.log('token expire:', tokenExp);
      
      if(current.getTime() > tokenExp.getTime()){
          console.log('todays date is bigger. token = expired')

          this.logout();
      }

    } else {
      this.Stream.emit({ isLoggedIn: false, text: 'Log in' });
    }
  }

  private emitStream(token: any) {
    var tokenObject = token;
    var ex = tokenObject[".expires"];
    this.Stream.emit({ isLoggedIn: true, text: 'Log out', userName: tokenObject.userName, Roles: tokenObject.roles, expires: tokenObject[".expires"] });
  }

  changePassword(model: { NewPassword: string; UserId: string }): Observable<any> {
    //return this.http.post('http://localhost:99/api/account/resetpassword', model);
    return this.http.post(environment.apiUrl + '/account/resetpassword', model);
  }

  getActiveUser() {
    return this.http.get(this.base + '/account/getActiveUser');
  }
}

class CustomEventEmitter extends Subject<any> {
  constructor() {
    super();
  }
  emit(value: any) { super.next(value); }
}


