<mat-spinner *ngIf="isLoading"></mat-spinner>
<div class="col-md-12" *ngIf="!isLoading">
  <h2 class="text-center">Translations - Background texts</h2>
  <p class="text-center">You can only translate the language you entered into your usersettings.</p>
  <hr />
  <div class="row">
    <div class="col-md-11">
      <div class="row mb-4" *ngFor="let c of codes">
        <div class="col-lg-12"><h4>Question {{c.OrderName}}: {{c.Text}}</h4></div>
        <div class="col-lg-12" *ngFor="let cb of c.CodeBackgrounds">
          <div class="col-lg-12 mt-2"><strong>{{cb.CultureName}}</strong></div>
          <div class="col-lg-12">
            <span class="mt-1">Question text</span>
            <textarea class="form-control" [(ngModel)]="cb.CbText" [readonly]="!cb.Editable"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 pl-0">
      <app-button id="appButton" [success]="success" [spinnerText]="'Save'" [text]="'Save'" [useIcon]="true" [useConfirm]="true" (onClick)="save()" [debug]="false" [useSpinner]="true" [showSpinner]="updating" [color]="'success btn-lg'"></app-button>
    </div>
  </div>
</div>
