import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../../components/base-component';
import { DataService } from '../../../../services/data-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
var TranslateLabelItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TranslateLabelItemComponent, _super);
    function TranslateLabelItemComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.onChange = new EventEmitter();
        _this.inEdit = false;
        return _this;
    }
    //moveTranslation(groupId: number) {
    //  this.service.moveTranslation(this.translationGroup.keyVal, this.translationGroup.Culture.Id, this.selectedGroup, groupId)
    //    .pipe(takeUntil(this.destroyed$))
    //    .subscribe(response => {
    //      this.onChange.emit();
    //      this.notify.success('translationMoved');
    //  });
    //}
    TranslateLabelItemComponent.prototype.updateTranslation = function () {
        var _this = this;
        this.service.updateTranslation(this.lang.keyVal, this.lang.Culture.Id, this.selectedGroup, this.lang.val)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (response) {
            _this.inEdit = false;
            _this.onChange.emit();
            _this.notify.success('translationUpdated');
        });
    };
    TranslateLabelItemComponent.prototype.toggleInEdit = function () {
        this.inEdit = !this.inEdit;
    };
    return TranslateLabelItemComponent;
}(BaseComponent));
export { TranslateLabelItemComponent };
