import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-substance-list',
  templateUrl: './substance-list.component.html',
  styleUrls: ['./substance-list.component.css']
})
export class SubstanceListComponent implements OnInit {
  drugGroup: any[];
  substanceGroups: any[];
  selectedGroup = 1;
  isLoading = true;
  movedSubstanceGroup: any;
  isAdmin: boolean;

  constructor(private service: DataService, private readonly notify: NotificationService) { }

  ngOnInit() {
    this.loadTranslations();
  }

  onGroupChanged() {
    this.substanceGroups = this.drugGroup.filter(g => g.Id == this.selectedGroup)[0].Substances;
   
  }

  loadTranslations() {
    this.service.getDrugs().subscribe(response => {
      this.drugGroup = response;
      console.log('druggroups:', this.drugGroup);
      this.substanceGroups = this.drugGroup.filter(g => g.Id == this.selectedGroup)[0].Substances;
      console.log('substance groups: ', this.substanceGroups);
      this.isLoading = false;
      this.isAdmin = this.substanceGroups[0].IsAdmin;
    })
  }

  onCheckChange(value) {
    value.Active = !value.Active
    this.service.updateActiveSubstance(value).subscribe(response => {
      this.notify.success('Active flag changed');
    })
  }

  moveSubstanceGroup(drugGroupId: number, substanceGroup: any) {
    console.log(substanceGroup, drugGroupId);
    this.service.moveSubstanceGroup(substanceGroup.Id, drugGroupId)
      .subscribe(response => {
        this.notify.success('substance group was moved');

        var index = this.substanceGroups.findIndex(sg => sg.Id == substanceGroup.Id);
        var movedSubstanceGroup = this.substanceGroups.splice(index, 1);
        var dGroup = this.drugGroup.find(dg => dg.Id == drugGroupId);
        dGroup.Substances.push(movedSubstanceGroup[0]);
        var drugGroupIndex = this.drugGroup.findIndex(dg => dg.Id == drugGroupId);

        this.drugGroup.splice(drugGroupIndex, 1, dGroup);
       
      });
  }

  addNewSubstance(substanceName: string, drugGroupId: number) {
    console.log('från add:', substanceName, drugGroupId)
    this.service.addNewSubstance(drugGroupId, substanceName).subscribe(respone => {
      this.notify.success('substance was succesfully added');
      
    })
  }


}
