import { Injectable, NgZone } from '@angular/core';
import { SuccessComponent } from '../components/success/success.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ErrorComponent } from '../components/error/error.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private readonly snackBar: MatSnackBar, private zone: NgZone, private readonly dialog: MatDialog) { }

  confirm(message: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      let dialogRef = this.dialog.open(DialogComponent, {
        data: {
          message: message
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(true);
        } else {
          observer.next(false);
        }

        observer.complete();
      })
    });
  }

  success(message: string) {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'notification-success',
      data: {
        message: message
      }
    });
    
   
  }

  error(message: string) {
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'notification-error',
      data: {
        message: message
      }
    });
  }
}
