
<td>{{lang.Culture}}</td>
<td *ngIf="!inEdit">{{lang.SubstanceName}}</td>
<td *ngIf="inEdit">
  <textarea class="form-control" [(ngModel)]="lang.SubstanceName" #val="ngModel"></textarea>
</td>

<td *ngIf="!inEdit" class="group-buttons" [ngClass]="{'no-top': !index}">
  
  <button class="btn btn-sm btn-info mx-2" (click)="toggleInEdit()" [disabled]="!lang.Editable">Edit</button>
</td>
<td *ngIf="inEdit" class="group-buttons" [ngClass]="{'no-top': !index}">
  <button class="btn btn-success" (click)="updateTranslation()">Save</button>
</td>


