import * as tslib_1 from "tslib";
import { DataService } from '../../services/data-service.service';
import { BaseComponent } from 'src/app/components/base-component';
var UserGuideComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserGuideComponent, _super);
    function UserGuideComponent(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    return UserGuideComponent;
}(BaseComponent));
export { UserGuideComponent };
