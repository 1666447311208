import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() text: string;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() color: string;
  @Input() useIcon: boolean;
  @Input() typeSubmit = false;
  @Input() icon: string;
  @Input() size: string;
  classNames: string = 'btn ';
  private iconClasses: string = 'ui icon ';
  @Input() useConfirm: boolean;
  @Input() confirmText: string = 'Do you want to continue?';
  @Input() right: boolean = false;
  @Output() onConfirmEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() useSpinner: boolean;
  @Input() spinnerType: string;
  @Input() spinnerText: string = 'Saves';
  @Input() showSpinner: boolean;
  @Input() debug: boolean = true;
  @Input() success: boolean;

  @Input() disabled: boolean;
  modal: any;
  constructor() {
    if (this.debug) {
      let data = [
        { Input_name: 'text', Input_type: 'string' },
        { Input_name: 'useIcon', Input_type: 'bool' },
        { Input_name: 'size', Input_type: 'string' },
        { Input_name: 'useConfirm', Input_type: 'bool' },
        { Input_name: 'confirmText', Input_type: 'string' },
        { Input_name: 'right', Input_type: 'bool' },
        { Input_name: 'onConfirm', Input_type: 'event' },
        { Input_name: 'onClick', Input_type: 'event' },
        { Input_name: 'useSpinner', Input_type: 'bool' },
        { Input_name: 'spinnerType', Input_type: 'string' },
        { Input_name: 'spinnerText', Input_type: 'string' },
        { Input_name: 'showSpinner', Input_type: 'bool' },
        { Input_name: 'debug', Input_type: 'bool' },
        { Input_name: 'disabled', Input_type: 'bool' }

      ];
    }
    this.modal = {
      show: function () {
        if (this.debug)
          alert('show modal');

        this.onConfirmed({ yes: true });
      }.bind(this)
    };
  }

  ngOnInit(): void {
    if (this.color != undefined)
      this.classNames += 'btn-' + this.color;
    else
      this.classNames += 'btn-default';

    if (this.right) {
      this.classNames += ' float-right';
      this.classNames += ' btn-margin';
    }

    this.iconClasses += this.icon;
  }

  ngAfterViewInit(): void {

  }

  click() {
    if (this.useSpinner)
      this.toggleSpinner();

    if (this.debug)
      console.log('useSpinner:' + this.useSpinner, 'showSpinner:' + this.showSpinner);

    if (this.useConfirm) {
      this.modal.show();
    }
    else {

      var self = this;
      if (this.debug) {
        setTimeout(x => {
          self.emitClick();
        },
          3000);
      } else {
        this.emitClick();
      }
    }
  }

  private toggleSpinner() {
    if (this.useSpinner)
      this.showSpinner = !this.showSpinner;
  }

  emitClick() {
    //this.toggleSpinner();
    this.onClick.emit({ message: 'no modal confirm' });
  }

  onConfirmed(result: any) {
    var ok = confirm(this.confirmText);
    result.yes = ok;
    if (result.yes) {
      if (this.debug)
        alert('YES');

      var self = this;

      if (this.debug) {
        setTimeout(x => {
          self.emitClick();
        },
          3000);
      } else {
        this.emitClick();
      }

    } else {

      if (this.debug)
        alert('NO');

      //this.toggleSpinner();
    }
  }

}
