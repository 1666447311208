import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LayoutGuideComponent } from './pages/layout/layout-guide/layout-guide.component';
import { LayoutMainComponent } from './pages/layout/layout-main/layout-main.component';
import { LoginComponent } from './pages/login/login.component';
import { SubstanceListComponent } from './pages/translation/substance-list/substance-list.component';
import { BackgroundComponent } from './pages/translation/background/background.component';
import { CriteriasComponent } from './pages/translation/criterias/criterias.component';
import { GroupsComponent } from './pages/translation/groups/groups.component';
import { TranslateLabelComponent } from './pages/translation/translate-label/translate-label.component';
import { TranslateQuestionTypeComponent } from './pages/translation/translate-question-type/translate-question-type.component';
import { TranslateQuestionComponent } from './pages/translation/translate-question/translate-question.component';
import { UserGuideComponent } from './pages/user-guide/user-guide.component';
import { ListComponent } from './pages/userunits/list/list.component';
import { AuthGuardService } from './services/auth-guard.service';
import { TranslateQuestionCategoryComponent } from './pages/translation/translate-question-category/translate-question-category.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
      {
        path: 'landing',
        component: LandingPageComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/question',
        component: TranslateQuestionComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/questionTypes',
        component: TranslateQuestionTypeComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/questionCategory',
        component: TranslateQuestionCategoryComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/labels',
        component: TranslateLabelComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/groups',
        component: GroupsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/languages',
        component: GroupsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/background',
        component: BackgroundComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'translation/substance',
        component: SubstanceListComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'users',
        component: ListComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'translation/criterias',
        component: CriteriasComponent,
        canActivate: [AuthGuardService]
      },
    ]
  },
  {
    path: 'user-guide',
    component: LayoutGuideComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: UserGuideComponent,
        canActivate: [AuthGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
