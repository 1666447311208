<div class="flex-container">
  <div class="flex-item" style="width: 100%;">
      <h2>Welcome to Sugar Administration!</h2>
      <p>You are logged in as <span class="username">{{tokenObject.userName}}
        </span> and you have the role/roles as: <span class="roles" *ngFor="let role of rolesArray">{{role}},
        </span></p>
      <div *ngIf="translatorRole">
        <p>Before you start the translation, please read the <a routerLink="/user-guide">User guide</a> to get the information needed.</p>
      </div>
  </div>
</div>
<div *ngIf="adminRole" class="flex-container">
  <div class="flex-item">
    <mat-card class="dashboard-card" style="color: rgba(59, 38, 100); background-color: rgba(153, 102, 255, 0.5);">
      <mat-card-header>
        <mat-card-title>
          {{totalInterviews}}
        </mat-card-title>
        <mat-card-subtitle style="color: rgba(59, 38, 100);">
           Interviews in total
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="flex-item">
    <mat-card class="dashboard-card" style="color: rgb(113, 90, 37);
    background-color: rgb(255, 205, 86, 0.5);">
      <mat-card-header>
        <mat-card-title>
          {{interviewsThisYear}}
        </mat-card-title>
        <mat-card-subtitle style="color:rgb(113, 90, 37)">
          Interviews this year
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="flex-item">
    <mat-card class="dashboard-card" 
    style="color: rgb(122, 12, 46);
    background-color: rgb(255, 99, 132, 0.5);">
      <mat-card-header>
        <mat-card-title>
          {{interviewsThisMonth}}
        </mat-card-title>
        <mat-card-subtitle style="color: rgb(122, 12, 46);">
          Interviews this month
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
</div>

<div *ngIf="adminRole" class="flex-container">
  <div class="flex-item">
    {{ totalUsers }} users in total
    <canvas id="DoughnutChart" width="300" height="300">
    </canvas>
  </div>

  <div class="flex-item">
    Interviews
    <canvas id="LineChart" width="600" height="400">
    </canvas>
  </div>
</div>


<hr />
<div class="flex-container">
  <div class="flex-item">
    <div *ngIf="adminRole">
      <button style="width: 280px; height: 50px; margin-right: 50px;" class="btn  btn-info" (click)="genereteRegLink()">
        <strong>Generate Registration Link</strong>
      </button>

      <div *ngIf="showRegLink" style="margin-top: 40px;">
        <input style="width: 450px;" [(ngModel)]="regLink" type="text" readonly>
        <button class="btn link btn-info" (click)="copyLink(regLink)"><i class="far fa-file-alt"></i> Copy
          Link</button>
      </div>
      <a target="blank" href="assets/files/registerFlowSugar.pdf" download>registerFlowSugar.pdf</a>
    </div>
  </div>

</div>

<!-- <div class="col-md-12" style="margin-top:45px;">
      <h2 class="text-center">Homepage - Sugar</h2>
      <hr />
      <div class="row">
        <div class="col-md-11">
          <p>Welcome to Sugar Administration! You are logged in as <span class="username">{{tokenObject.userName}}
            </span> and you have the role/roles as: <span class="roles" *ngFor="let role of rolesArray">{{role}},
            </span></p>
          <p><a target="_blank" href="/user-guide" routerLink="/user-guide">Open the User Guide</a></p>

          <div *ngIf="adminRole">
            <a target="blank" href="assets/files/registerFlowSugar.pdf" download>registerFlowSugar.pdf</a>
          </div>
        </div>



        <div *ngIf="adminRole" style="text-align: center; margin: 50px auto 0;">
          <button style="width: 280px; height: 50px;" class="btn  btn-info" (click)="genereteRegLink()">
            <strong>Generate Registration Link</strong>
          </button>

          <div *ngIf="showRegLink" style="margin-top: 40px;">
            <input style="width: 450px;" [(ngModel)]="regLink" type="text" readonly>
            <button class="btn link btn-info" (click)="copyLink(regLink)"><i class="far fa-file-alt"></i> Copy
              Link</button>
          </div>
        </div>
      </div>
    </div> -->