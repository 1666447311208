import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { NotificationService } from "../services/notification.service";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  ONE_HOUR = 60 * 60 * 1000;

  constructor(private readonly router: Router, private readonly notify: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthHeader(req);

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with reponse if you want
      }
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 401:
            this.router.navigateByUrl('/login');
            break;
          case 400:
            this.notify.error('notificationBadRequest');
            console.log(error);
            break;
          case 500:
            this.notify.error('notificationInternalServerError');
            console.log(error);
            break;
          default:
            break;
        }
      }
    }));
  }

  private addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    let token: any = JSON.parse(localStorage.getItem('admin_token'));
    if (token) {
      var contentType = req.headers.get('Content-Type');
      if (contentType !== 'application/x-www-form-urlencoded') {
        req = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access_token}`
          }
        });
      }
    }
    return req;
  }
}
