import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../../components/base-component';
import { DataService } from '../../../../services/data-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-group-item',
  templateUrl: './group-item.component.html',
  styleUrls: ['./group-item.component.css']
})

export class GroupItemComponent extends BaseComponent {
  @Input() group: { Id: number, Key: string, Name: string };
  @Input() index: number;
  @Output() onDelete = new EventEmitter();
  @Output() onUpdate = new EventEmitter();
  inEdit = false;

  constructor(private readonly service: DataService, private readonly notify: NotificationService) {
    super();
  }

  deleteGroup() {
    this.service.deleteGroup(this.group.Id).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.onDelete.emit();
      this.notify.success('groupDeleted');
    });
  }

  updateGroup() {
    this.service.updateGroup(this.group).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.inEdit = false;
      this.onUpdate.emit();
      this.notify.success('groupUpdated');
    })
  }

  toggleInEdit() {
    this.inEdit = !this.inEdit;
  }
}
