import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-translate-question-type',
  templateUrl: './translate-question-type.component.html',
  styleUrls: ['./translate-question-type.component.css']
})

export class TranslateQuestionTypeComponent extends BaseComponent {
  selectedQuestionType: number;
  cultureCodes: Array<any> = new Array<any>();
  data: any = [];

  questions: any = [];
  allQuestions: any = [];

  isLoading = true;
  updating = false;
  saveSuccess = false;

  constructor(private service: DataService, private readonly notify: NotificationService) {
    super();
    this.load();
  }

  load() {
    this.service.listQuestionTypes().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.data = response;
      console.log('data', this.data);
      this.allQuestions = this.data.Questions;
      var selected = this.data.QuestionTypes[0];
      this.selectedQuestionType = selected.Id;
      this.cultureCodes = selected.GroupedItems;
      this.questions = this.allQuestions.filter(x => x.QuestionTypeId == this.selectedQuestionType);
      this.isLoading = false;
      $(window).scroll(function () {
        $('#appButton').css({ top: $(this).scrollTop() });
      });
    });
  }

  changeQuestionType() {
    this.cultureCodes = this.data.QuestionTypes.find(x => x.Id == this.selectedQuestionType).GroupedItems;
    this.questions = this.allQuestions.filter(x => x.QuestionTypeId == this.selectedQuestionType);
  }

  saveTranslation() {
    this.updating = true;
    var items = [];
    // this.data[0].GroupedItems.filter(x => x.Items.length > 0).map(y => Array.prototype.push.apply(items, y.Items));
    // var items = this.data.QuestionTypes.find(x => x.Id == this.selectedQuestionType).GroupedItems.map(y => y.Items);

     this.data.QuestionTypes.find(x => x.Id == this.selectedQuestionType).GroupedItems.map(y => Array.prototype.push.apply(items, y.Items));

    this.service.saveQuestionTypeTranslations(items).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.updating = false;
      this.loadAfterSaved();
      this.notify.success('translationUpdated');
    });
  }

  loadAfterSaved(){
      this.service.listQuestionTypes().pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.data = response;
      console.log('data', this.data);
      this.allQuestions = this.data.Questions;
      var selected = this.data.QuestionTypes.find(x => x.Id == this.selectedQuestionType);
      this.selectedQuestionType = selected.Id;
      this.cultureCodes = selected.GroupedItems;
      this.questions = this.allQuestions.filter(x => x.QuestionTypeId == this.selectedQuestionType);
      this.isLoading = false;
      $(window).scroll(function () {
        $('#appButton').css({ top: $(this).scrollTop() });
      });
    });
  }

  //Loads all translations of the selected question
  private loadCultureQuestion(questionId: number) {

  }
}
