import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base-component';
import { DataService } from '../../../services/data-service.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-translate-label',
  templateUrl: './translate-label.component.html',
  styleUrls: ['./translate-label.component.css']
})

export class TranslateLabelComponent extends BaseComponent {
  groups: { Id: number, Key: string, Name: string, Translations: { KeyVal: string, Val: string, culture: any }[] }[];
  translationGroups: any[];
  selectedGroup = 1;
  isLoading = true;
  userRoleAuth: boolean;

  private tokenKey: string = "admin_token";

  constructor(private readonly service: DataService, private readonly notify: NotificationService) {
    super();
    this.loadTranslations();

    var token = localStorage.getItem(this.tokenKey);
    if (token != undefined) {
      var tokenObject = JSON.parse(token);

      let userRoles = tokenObject.roles;
        if (userRoles.includes('Admin')) {
          this.userRoleAuth = true;
        } else {
          this.userRoleAuth = false;
        }
      }
  }

  onGroupChanged() {
    this.translationGroups = this.groups.filter(g => g.Id == this.selectedGroup)[0].Translations;
  }

  private loadTranslations() {
    this.service.getGroups(true).pipe(takeUntil(this.destroyed$)).subscribe(response => {
      this.groups = response;

      if(this.userRoleAuth == false) {
        this.groups = this.groups.filter(g => g.Key !== 'licensAgreement');
      }

      this.translationGroups = this.groups.filter(g => g.Id == this.selectedGroup)[0].Translations;
      this.isLoading = false;

      console.log('All groups: ', this.groups)
      console.log('All translationGroups: ', this.translationGroups);
    });
  }

  moveTranslationGroup(groupId: number, translationGroup: any) {
    console.log(groupId, translationGroup);
    this.service.moveTranslationGroup(groupId, translationGroup.key)
      .subscribe(response => {
        this.notify.success('translation group was moved');

        var index = this.translationGroups.findIndex(tg => tg.key == translationGroup.key);
        var movedTranslationGroup = this.translationGroups.splice(index, 1);
        var tGroup = this.groups.find(tg => tg.Id == groupId);
        tGroup.Translations.push(movedTranslationGroup[0]);
        var tGroupIndex = this.groups.findIndex(tg => tg.Id == groupId);

        this.groups.splice(tGroupIndex, 1, tGroup);

      });
  }
}
