import { NgZone } from '@angular/core';
import { SuccessComponent } from '../components/success/success.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ErrorComponent } from '../components/error/error.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/material/dialog";
var NotificationService = /** @class */ (function () {
    function NotificationService(snackBar, zone, dialog) {
        this.snackBar = snackBar;
        this.zone = zone;
        this.dialog = dialog;
    }
    NotificationService.prototype.confirm = function (message) {
        var _this = this;
        return new Observable(function (observer) {
            var dialogRef = _this.dialog.open(DialogComponent, {
                data: {
                    message: message
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    observer.next(true);
                }
                else {
                    observer.next(false);
                }
                observer.complete();
            });
        });
    };
    NotificationService.prototype.success = function (message) {
        this.snackBar.openFromComponent(SuccessComponent, {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'notification-success',
            data: {
                message: message
            }
        });
    };
    NotificationService.prototype.error = function (message) {
        this.snackBar.openFromComponent(ErrorComponent, {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'notification-error',
            data: {
                message: message
            }
        });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.MatSnackBar), i0.inject(i0.NgZone), i0.inject(i2.MatDialog)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
