import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { BaseComponent } from '../base-component';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
var ResetPasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResetPasswordComponent, _super);
    function ResetPasswordComponent(service, router, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.router = router;
        _this.notify = notify;
        _this.onSave = new EventEmitter();
        _this.onCancel = new EventEmitter();
        _this.isLoading = false;
        _this.error = false;
        return _this;
    }
    ResetPasswordComponent.prototype.cancel = function () {
        this.onCancel.emit();
    };
    ResetPasswordComponent.prototype.changePassword = function () {
        var _this = this;
        this.isLoading = true;
        this.service.changePassword({ NewPassword: this.psw, UserId: this.id }).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            //this.router.navigateByUrl('/login');
            _this.notify.success('passwordUpdated');
            _this.onSave.emit();
        }, function (error) {
            _this.error = true;
            console.log(error);
            _this.isLoading = false;
        });
    };
    return ResetPasswordComponent;
}(BaseComponent));
export { ResetPasswordComponent };
