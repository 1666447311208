/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./success.component";
import * as i4 from "@angular/material/snack-bar";
var styles_SuccessComponent = [i0.styles];
var RenderType_SuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessComponent, data: {} });
export { RenderType_SuccessComponent as RenderType_SuccessComponent };
export function View_SuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data.message)); _ck(_v, 2, 0, currVal_0); }); }
export function View_SuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-success", [], null, null, null, View_SuccessComponent_0, RenderType_SuccessComponent)), i1.ɵdid(1, 49152, null, 0, i3.SuccessComponent, [i4.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var SuccessComponentNgFactory = i1.ɵccf("app-success", i3.SuccessComponent, View_SuccessComponent_Host_0, {}, {}, []);
export { SuccessComponentNgFactory as SuccessComponentNgFactory };
