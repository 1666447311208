<mat-spinner *ngIf="isLoading"></mat-spinner>
<div *ngIf="!isLoading">
  <h2 class="px-3">Användare & Enheter</h2>
  <table class="table">
    <tbody>
      <tr>
        <td>
          <button class="btn btn-light float-right" (click)="toggleCreateUser()">Ny Användare</button>
          <button class="btn btn-light float-right btn-margin" (click)="toggleCreateUnit()">Ny enhet</button>
          <button class="btn btn-light float-right btn-margin" (click)="toggleResetPassword()">Byt lösenord</button>
          <button class="btn btn-light float-right btn-margin hidden" (click)="exportUsers()">Exportera användare</button>
          <button class="btn btn-light float-right btn-margin hidden" (click)="exportInterviews()">Exportera intervjuer</button>
        </td>
      </tr>
    </tbody>
  </table>
  <app-add-user [units]="units" [visible]="createUserVisible" (onCancel)="cancelEdit()" (onSave)="loadUnits(true)"></app-add-user>
  <app-add-unit [visible]="createUnitVisible" (onCreated)="loadUnits(false)" (onCancel)="cancelEdit()"></app-add-unit>
  <app-edit-user [units]="units" [visible]="editUserVisible" [model]="user" (onCancel)="cancelEdit()" (onSave)="loadUsers(false)"></app-edit-user>
  <app-reset-password [visible]="resetPasswordVisible" (onSave)="toggleResetPassword()" (onCancel)="toggleResetPassword()"></app-reset-password>

  <div style="margin-bottom: 20px;">
    <h5>Sök efter användare</h5>
    <div class="input-group" style="width: 40%;">
      <input type="text" [formControl]="userSearchCtrl" class="form-control" placeholder="Skriv namn eller e-post" aria-describedby="search">
      <div class="input-group-append">
        <button class="btn btn-light input-group-text" (click)="clear(userSearchCtrl)" id="search"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <p style="font-style: italic; font-size: small; margin-left: 5px;" *ngIf="noMatch">Ingen träff</p>

    <button class="btn btn-light" style="width: 80px; margin-top: 5px;" (click)="searchUsers()">Sök</button>
  </div>

  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item active">
      <a class="nav-link active" data-toggle="tab" href="#users" role="tab">Användare</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#units" role="tab">Enheter</a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane active" id="users" role="tabpanel">
      <select class="form-control my-3" [(ngModel)]="selectedUnit" (change)="filterUsers()">
        <option [value]="-1" selected disabled>Välj enhet</option>
        <option *ngFor="let unit of units" [value]="unit.id">{{unit.name}}</option>
      </select>
      <table class="table table-striped">
        <thead>
          <tr *ngIf="selectedUnit != -1">
            <th scope="col"><a class="sort-link no-select" (click)="sortUserBy('name')">Namn</a></th>
            <th scope="col">Epost</th>
            <th scope="col">Aktiv</th>
            <th scope="col">Roller</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of filteredUsers">
            <td>{{u.name}}</td>
            <td>{{u.email}}</td>
            <td>{{u.active ? 'Ja' : 'Nej'}}</td>
            <td>
              <ul>
                <li *ngFor="let r of u.roles">{{r.role}}</li>
              </ul>
            </td>
            <td>
              <app-button [text]="'Ta bort'" [debug]="false" [color]="'danger'" [right]="true" [useSpinner]="false" [showSpinner]="u.spinnerDeleteUser" [spinnerText]="'Utför...'" (onClick)="deleteUser(u)"></app-button>
              <app-button [text]="'Ändra'" [debug]="false" [color]="'info'" [right]="true" [useSpinner]="false" [useConfirm]="false" [spinnerText]="'Utför...'" (onClick)="editUser(u)"></app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tab-pane" id="units" role="tabpanel">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Namn</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of units">
            <td>
              <div *ngIf="!u.editingUnit">{{u.name}}</div>
              <div *ngIf="u.editingUnit"><input type="text" [(ngModel)]="u.name" class="form-control" /></div>
            </td>
            <td>
              <app-button [hidden]="u.editingUnit" [text]="'Ta bort'" [debug]="false" [useConfirm]="true" [useSpinner]="false" [showSpinner]="u.removingUnit" [color]="'btn btn-danger pull-right btn-margin'" [right]="true" (onClick)="deleteUnit(u)"></app-button>
              <app-button [hidden]="u.editingUnit" [text]="'Ändra'" [debug]="false" [color]="'btn btn-info float-right'" (onClick)="editUnit(u)"></app-button>
              <app-button [hidden]="!u.editingUnit" [text]="'Spara'" [useSpinner]="true" [showSpinner]="updating" [debug]="false" [color]="'btn btn-success float-right'" (onClick)="saveUnit(u)"></app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

