import * as tslib_1 from "tslib";
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var LoginService = /** @class */ (function () {
    function LoginService(http, router) {
        this.http = http;
        this.router = router;
        this.base = environment.apiUrl; //Load from environment.ts
        this.tokenKey = "admin_token";
        this.baseLogin = environment.apiUrl + "/token"; //Load from environment.ts
        this.Stream = new CustomEventEmitter();
    }
    LoginService.prototype.login = function (usr, psw) {
        var _this = this;
        return new Observable(function (observer) {
            var data = "userName=" + encodeURIComponent(usr) + "&password=" + encodeURIComponent(psw) + "&grant_type=password";
            _this.http.post(_this.baseLogin, data).subscribe(function (response) {
                var tokenObject = response;
                localStorage.setItem(_this.tokenKey, JSON.stringify(tokenObject));
                _this.getActiveUser().subscribe(function (roles) {
                    tokenObject.roles = roles;
                    localStorage.setItem(_this.tokenKey, JSON.stringify(tokenObject));
                    _this.emitStream(tokenObject);
                    observer.next(true);
                    observer.complete();
                });
            }, function (error) {
                observer.next(false);
                observer.complete();
            });
        });
    };
    LoginService.prototype.logout = function () {
        localStorage.removeItem(this.tokenKey);
        this.Stream.emit({ isLoggedIn: false, text: 'Log in' });
        this.router.navigate(['/login']);
    };
    LoginService.prototype.isLoggedIn = function () {
        var token = localStorage.getItem(this.tokenKey);
        if (token != undefined) {
            var tokenObject = JSON.parse(token);
            this.emitStream(tokenObject);
        }
        else {
            this.Stream.emit({ isLoggedIn: false, text: 'Log in' });
        }
    };
    LoginService.prototype.checkValidity = function () {
        var token = localStorage.getItem(this.tokenKey);
        if (token != undefined) {
            var tokenObject = JSON.parse(token);
            var current = new Date();
            var tokenExp = new Date(tokenObject[".expires"]);
            console.log('current date:', current);
            console.log('token expire:', tokenExp);
            if (current.getTime() > tokenExp.getTime()) {
                console.log('todays date is bigger. token = expired');
                this.logout();
            }
        }
        else {
            this.Stream.emit({ isLoggedIn: false, text: 'Log in' });
        }
    };
    LoginService.prototype.emitStream = function (token) {
        var tokenObject = token;
        var ex = tokenObject[".expires"];
        this.Stream.emit({ isLoggedIn: true, text: 'Log out', userName: tokenObject.userName, Roles: tokenObject.roles, expires: tokenObject[".expires"] });
    };
    LoginService.prototype.changePassword = function (model) {
        //return this.http.post('http://localhost:99/api/account/resetpassword', model);
        return this.http.post(environment.apiUrl + '/account/resetpassword', model);
    };
    LoginService.prototype.getActiveUser = function () {
        return this.http.get(this.base + '/account/getActiveUser');
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
var CustomEventEmitter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomEventEmitter, _super);
    function CustomEventEmitter() {
        return _super.call(this) || this;
    }
    CustomEventEmitter.prototype.emit = function (value) { _super.prototype.next.call(this, value); };
    return CustomEventEmitter;
}(Subject));
