import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(router) {
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (localStorage.admin_token) {
                observer.next(true);
            }
            else {
                observer.next(false);
                _this.router.navigateByUrl('/login');
            }
        });
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
