
<div class="navbar" [ngClass]="{'navbar-test' : testNav == true}">
  <div class="container">
    <nav class="navbar navbar-expand-lg" [ngClass]="{'navbar-test' : testNav == true}">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" [hidden]="!isLoggedIn" routerLink="/landing"><i class="fas fa-home"></i> Homepage</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" [hidden]="!userRoleAuth || !isLoggedIn" routerLink="/users"><i class="fas fa-users"></i> Users/units <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" [hidden]="!isLoggedIn" target="_blank" href="/user-guide" routerLink="/user-guide"><i class="fas fa-book-open"></i> User guide <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [hidden]="!isLoggedIn" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-cog"></i> Translation
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" [routerLink]="['/translation/question']">Questions</a>
            <a class="dropdown-item" [routerLink]="['/translation/questionTypes']">Question types</a>
            <a class="dropdown-item" [routerLink]="['/translation/questionCategory']">Question category</a>
            <a class="dropdown-item" [routerLink]="['/translation/labels']">Page texts</a>
            <a class="dropdown-item" [hidden]="!userRoleAuth" [routerLink]="['/translation/languages']">Groups</a>
            <a class="dropdown-item" [routerLink]="['/translation/criterias']">Criteria</a>
            <a class="dropdown-item" [routerLink]="['/translation/background']">Background</a>
            <a class="dropdown-item" [routerLink]="['/translation/substance']">Substance list</a>
          </div>
        </li>
        <li class="nav-item ml-auto">
          <a (click)="logInOut()" class="nav-link">
            <i class="fas fa-sign-in-alt"></i> {{loginOrOut}}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<!--<div class="navbar hidden-print">
        <div class="container">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="navbar-collapse collapse">
            <ul class="nav navbar-nav navbar-left">
              <li class="empty-nav"></li>
              <li class="active"><a routerLink="/users"><i class="fa fa-file-o"></i> Användare/Enheter</a></li>
              <li class="dropdown hidden">
                <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-cog"></i>  <span class="caret"></span></a>
                <ul class="dropdown-menu">
                  <li><a ui-sref="admin.users"><i class="fa fa-user"></i> Användarhantering</a></li>
                  <li><a ui-sref="translation.groups"><i class="fa fa-globe"></i> Översättning</a></li>
                </ul>
              </li>
              <li class="dropdown">
                  <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-cog"></i>  Översättning</a>

                  <ul class="dropdown-menu">
                    <li><a [routerLink]="['/translation/question']"><i class="fa fa-cog"></i> Frågor</a></li>
                    <li><a [routerLink]="['/translation/questionTypes']"><i class="fa fa-cog"></i> Frågetyper</a></li>
                    <li><a [routerLink]="['/translation/labels']"><i class="fa fa-cog"></i> Sidtexter</a></li>
                    <li><a [routerLink]="['/translation/languages']"><i class="fa fa-cog"></i> Språk</a></li>
                  </ul>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" >
              <li>
                <a (click)="logInOut()" class="pointer">
                  <i class="fa fa-sign-out"></i> {{loginOrOut}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>-->
