import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.css']
})
export class LayoutMainComponent implements OnInit {
  env: any = environment;
  showTestHeader: boolean = false;

  constructor() {
    if(this.env.test == true ) {
      this.showTestHeader = true;
    }
   }

  ngOnInit() {
  }

}
