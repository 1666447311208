import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base-component';
import { DataService } from '../../../services/data-service.service';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
var CriteriasComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CriteriasComponent, _super);
    function CriteriasComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.isLoading = true;
        _this.updating = false;
        return _this;
    }
    CriteriasComponent.prototype.ngOnInit = function () {
        this.load();
        $(window).scroll(function () {
            //$('#appButton').css({ top: ($(this).scrollTop() + 95) + 'px' });
            $('#appButton').css({ top: $(this).scrollTop() });
        });
    };
    CriteriasComponent.prototype.load = function () {
        var _this = this;
        this.service.getCriterias().pipe(takeUntil(this.destroyed$)).subscribe(function (criterias) {
            _this.criterias = criterias;
            _this.isLoading = false;
        });
    };
    CriteriasComponent.prototype.save = function () {
        var _this = this;
        this.updating = true;
        this.service.saveCriterias(this.criterias).pipe(takeUntil(this.destroyed$)).subscribe(function () {
            _this.updating = false;
            _this.notify.success('translationUpdated');
            _this.load();
        });
    };
    return CriteriasComponent;
}(BaseComponent));
export { CriteriasComponent };
