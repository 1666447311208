import * as tslib_1 from "tslib";
import { DataService } from '../../../services/data-service.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
var GroupsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GroupsComponent, _super);
    function GroupsComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.isLoading = true;
        _this.loadGroups();
        return _this;
    }
    GroupsComponent.prototype.loadGroups = function () {
        var _this = this;
        this.service.getGroups(false).pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            _this.groups = response;
            _this.isLoading = false;
        });
    };
    GroupsComponent.prototype.addGroup = function () {
        var _this = this;
        this.service.addGroup(this.name).pipe(takeUntil(this.destroyed$)).subscribe(function () {
            _this.name = '';
            _this.loadGroups();
            _this.notify.success('groupAdded');
        });
    };
    return GroupsComponent;
}(BaseComponent));
export { GroupsComponent };
