<div *ngIf="visible">
  <h3>Skapa ny användare</h3>
  <form [formGroup]="form" (ngSubmit)="addUser(form.value)">
    <table class="table" style="width: 100%;">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Förnamn *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['firstname'].valid && form.controls['firstname'].touched">Förnamn är ett obligatoriskt fält</div>
            <input type="text" class="form-control" formControlName="firstname" />
          </td>
          <td>
            <strong>Efternamn *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['lastname'].valid && form.controls['lastname'].touched">Efternamn är ett obligatoriskt fält</div>
            <input type="text" class="form-control" formControlName="lastname" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Roll *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['formroles'].valid && form.controls['formroles'].touched">Roll är ett obligatoriskt fält</div>
            <div formArrayName="formroles">
              <div *ngFor="let r of roles?.controls; let i=index" [formGroupName]="i">
                <input type="checkbox" formControlName="selected" />
                {{r.value.name}}
              </div>
            </div>
          </td>
          <td>
            <strong>Email *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['email'].valid && form.controls['email'].touched">E-post är ett obligatoriskt fält</div>
            <input type="text" class="form-control" formControlName="email" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Telefon</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="phone" />
          </td>
          <td>
            <strong>Adress</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="address" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Postnr</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="zip" />
          </td>
          <td>
            <strong>Ort</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="city" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Titel</strong>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="title" />
          </td>
          <td>
            <strong>Språk *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['language'].valid && form.controls['language'].touched">Språk är ett obligatoriskt fält</div>
            <select class="form-control" formControlName="language">
              <option *ngFor="let l of languages?.controls; let i=index" [value]="l.value.laId">{{l.value.laName}}</option>
            </select>
          </td>

        </tr>
        <tr>
          <td>
            <strong>Startdatum *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['start_date'].valid && form.controls['start_date'].touched">Startdatum är ett obligatoriskt fält</div>
            <input type="date" class="form-control" formControlName="start_date" />
          </td>
          <td>
            <strong>Fakturering *</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['invoice_date'].valid && form.controls['invoice_date'].touched">Faktureringsdatum är ett obligatoriskt fält</div>
            <input type="date" class="form-control" formControlName="invoice_date" />
          </td>
        </tr>
        <tr>
          <td>
            <strong>Aktiv</strong>
          </td>
          <td>
            <div class="alert" *ngIf="!form.controls['active'].valid && form.controls['active'].touched">Aktiv är ett obligatoriskt fält</div>
            <input type="checkbox" formControlName="active" />
          </td>
          <td>
            <strong>Enhet *</strong>
          </td>
          <td>
            <select class="form-control" formControlName="unit">
              <option [value]="-1">Välj enhet</option>
              <option [value]="u.id" *ngFor="let u of units">{{u.name}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <button type="submit" class="btn btn-success pull-right btn-margin" [disabled]="!form.valid">Skapa</button>
            <app-button [text]="'Stäng'" [debug]="false" [useSpinner]="false" [right]="true" (onClick)="cancel()"></app-button>
          </td>
        </tr>
        <tr *ngIf="serverError.length > 0">
          <td colspan="4">
            <h3>Det gick inte att skapa användaren</h3>
            <div class="alert" style="display: block;">
              <ul>
                <li *ngFor="let e of serverError">{{e}}</li>
              </ul>
            </div>
          </td>
        </tr>
        <tr *ngIf="created">
          <td colspan="4">
            <h3 class="alert green float-right">En ny användare är skapad</h3>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

</div>
