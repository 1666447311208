import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
export function customValidateArray() {
    return function (formArray) {
        var valid = false;
        formArray.controls.forEach(function (x) {
            if (x.value.selected) {
                valid = true;
            }
        });
        return valid ? null : { error: 'Välj minst en roll' };
    };
}
;
var AddUserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddUserComponent, _super);
    function AddUserComponent(fb, service, notify) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.service = service;
        _this.notify = notify;
        _this.serverError = new Array();
        _this.created = false;
        _this.onCancel = new EventEmitter();
        _this.onSave = new EventEmitter();
        _this.rolesData = [];
        _this.rolesData.push({ name: 'Admin', id: 1, selected: false });
        _this.rolesData.push({ name: 'User', id: 2, selected: false });
        _this.rolesData.push({ name: 'Translator', id: 4, selected: false });
        _this.service.getLanguages().subscribe(function (l) {
            _this.languageData = l;
            _this.initForm();
        });
        return _this;
    }
    AddUserComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            'firstname': [null, Validators.required],
            'lastname': [null, Validators.required],
            'role': [-1],
            'email': [null, Validators.email],
            'phone': [null],
            'address': [null],
            'zip': [null],
            'city': [null],
            'title': [null],
            'language': [null, Validators.required],
            'formlanguages': new FormArray([]),
            'start_date': [null, Validators.required],
            'invoice_date': [null, Validators.required],
            'active': [false, Validators.required],
            'unit': [-1, Validators.min(1)],
            'formroles': new FormArray([])
        });
        this.setRoles(this.rolesData);
        this.setLanguage(this.languageData);
    };
    AddUserComponent.prototype.cancel = function () {
        this.onCancel.emit();
    };
    AddUserComponent.prototype.setRoles = function (role) {
        var _this = this;
        this.rolesData.map(function (x) { return x.selected = false; });
        var rolesFGs = this.rolesData.map(function (r) { return _this.fb.group(r); });
        var roleFormArray = this.fb.array(rolesFGs);
        roleFormArray.setValidators(customValidateArray());
        this.form.setControl('formroles', roleFormArray);
    };
    Object.defineProperty(AddUserComponent.prototype, "roles", {
        get: function () {
            return this.form.get('formroles');
        },
        enumerable: true,
        configurable: true
    });
    AddUserComponent.prototype.setLanguage = function (language) {
        var _this = this;
        var languagesFGs = this.languageData.map(function (l) { return _this.fb.group(l); });
        var languagesFormArray = this.fb.array(languagesFGs);
        this.form.setControl('formlanguages', languagesFormArray);
    };
    Object.defineProperty(AddUserComponent.prototype, "languages", {
        get: function () {
            return this.form.get('formlanguages');
        },
        enumerable: true,
        configurable: true
    });
    AddUserComponent.prototype.addUser = function (post) {
        var _this = this;
        this.created = false;
        this.serverError = new Array();
        this.service.createUser(post).pipe(takeUntil(this.destroyed$)).subscribe(function () {
            _this.notify.success('userCreated');
            _this.onSave.emit();
        }, function (e) {
            var error = e;
            if (error.ModelState) {
                for (var p in error.ModelState) {
                    var array = error.ModelState[p];
                    for (var i = 0; i < array.length; i++) {
                        _this.serverError.push(array[i]);
                    }
                }
            }
        });
    };
    return AddUserComponent;
}(BaseComponent));
export { AddUserComponent };
