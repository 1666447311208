import * as tslib_1 from "tslib";
import { DataService } from '../../../services/data-service.service';
import { BaseComponent } from '../../../components/base-component';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { FormControl } from '@angular/forms';
var ListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListComponent, _super);
    function ListComponent(service, notify) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.notify = notify;
        _this.messages = new Array();
        _this.createMessageVisible = false;
        _this.isLoading = true;
        _this.updating = false;
        _this.userSortOrderAsc = true;
        _this.noMatch = false;
        _this.userSearchCtrl = new FormControl();
        _this.user = null;
        _this.users = new Array();
        _this.units = new Array();
        _this.loadUnits(true);
        return _this;
    }
    ListComponent.prototype.loadUnits = function (loadUsers) {
        var _this = this;
        this.createUnitVisible = false;
        this.service.listUnits().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            var units = response;
            _this.units = new Array();
            for (var i = 0; i < units.length; i++) {
                _this.units.push({ name: units[i].uName, id: units[i].uId });
            }
            _this.sortUnitsByName();
            // this.selectedUnit = this.units[0].id;
            _this.selectedUnit = -1;
            if (loadUsers)
                _this.loadUsers(false);
        });
    };
    ListComponent.prototype.sortUserBy = function (column) {
        switch (column) {
            case "name":
                this.sortUsersByName();
                break;
            case "email":
                //TODO:
                break;
            case "active":
                //TODO:
                break;
            case "roles":
                //TODO:
                break;
            default:
                break;
        }
    };
    ListComponent.prototype.sortUsersByName = function () {
        var _this = this;
        this.filteredUsers.sort(function (left, right) {
            if (!_this.userSortOrderAsc) {
                if (left.name.toLowerCase() > right.name.toLowerCase())
                    return -1;
                else if (left.name.toLowerCase() < right.name.toLowerCase())
                    return 1;
                else
                    return 0;
            }
            else {
                if (left.name.toLowerCase() < right.name.toLowerCase())
                    return -1;
                else if (left.name.toLowerCase() > right.name.toLowerCase())
                    return 1;
                else
                    return 0;
            }
        });
        //After sorting, switch the sort order
        this.userSortOrderAsc = !this.userSortOrderAsc;
    };
    ListComponent.prototype.sortUnitsByName = function () {
        this.units.sort(function (left, right) {
            if (left.name.toLowerCase() < right.name.toLowerCase())
                return -1;
            else if (left.name.toLowerCase() > right.name.toLowerCase())
                return 1;
            else
                return 0;
        });
    };
    ListComponent.prototype.loadUsers = function (loadUnits) {
        var _this = this;
        this.editUserVisible = false;
        this.createUserVisible = false;
        this.service.listUsers().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            var users = response;
            _this.users = new Array();
            for (var i = 0; i < users.length; i++) {
                _this.users.push({
                    id: users[i].id,
                    name: users[i].firstname,
                    email: users[i].email,
                    active: users[i].active,
                    roles: users[i].userRoles,
                    unit: users[i].unit
                });
            }
            _this.filterUsers();
            _this.isLoading = false;
            _this.sortUsersByName();
            if (loadUnits)
                _this.loadUnits(false);
        });
    };
    ListComponent.prototype.filterUsers = function () {
        var _this = this;
        this.userSortOrderAsc = true;
        this.filteredUsers = this.users.filter(function (u) { return u.unit == _this.selectedUnit; });
        this.sortUsersByName();
    };
    ListComponent.prototype.searchUsers = function () {
        var searchWord = this.userSearchCtrl.value.toLowerCase();
        var resultList = [];
        if (searchWord) {
            // result = this.getUsersByName(searchWord);
            this.users.forEach(function (u) {
                if (u != null) {
                    if (u.name != null) {
                        if (u.name.toLowerCase().includes(searchWord)) {
                            resultList.push(u);
                        }
                    }
                    if (resultList.indexOf(u) == -1 && u.email != null) {
                        if (u.email.toLowerCase().includes(searchWord)) {
                            resultList.push(u);
                        }
                    }
                }
            });
            this.filteredUsers = resultList.sort(function (a, b) { return (a.name < b.name) ? 1 : ((a.name > b.name) ? -1 : 0); });
            if (this.filteredUsers.length <= 0) {
                this.noMatch = true;
            }
            else {
                this.noMatch = false;
            }
        }
        console.log(this.filteredUsers);
    };
    ListComponent.prototype.clear = function (ctrl) {
        ctrl.setValue('');
        ctrl.markAsDirty();
        this.noMatch = false;
    };
    ListComponent.prototype.toggleCreateMessage = function () {
        this.createMessageVisible = !this.createMessageVisible;
    };
    ListComponent.prototype.toggleEditMessage = function (message) {
        message.inEdit = !message.inEdit;
    };
    ListComponent.prototype.exportUsers = function () {
        var _this = this;
        this.service.exportUsers().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            var users = new Array('Namn;Epost;Enhet;Licensstart;Adress;Stad;Postnummer;Telefon;Yrkestitel');
            response.forEach(function (user) { return users.push(user); });
            _this.downloadCSV(users, 'Users.csv');
        });
    };
    ListComponent.prototype.exportInterviews = function () {
        var _this = this;
        this.service.exportInterviews().pipe(takeUntil(this.destroyed$)).subscribe(function (response) {
            var interviews = new Array('Skapad;Avslutad;Uno-kod;Namn;Status');
            response.forEach(function (interview) { return interviews.push(interview); });
            _this.downloadCSV(interviews, 'Interviews.csv');
        });
    };
    ListComponent.prototype.downloadCSV = function (csv, filename) {
        var link = document.createElement('a');
        link.setAttribute('href', "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv.join('\n')));
        link.setAttribute('download', filename);
        link.click();
    };
    ListComponent.prototype.editUser = function (user) {
        window.scrollTo(0, 0);
        this.user = user;
        this.toggleEditUser();
    };
    ListComponent.prototype.editUnit = function (unit) {
        unit.editingUnit = !unit.editingUnit;
    };
    ListComponent.prototype.toggleResetPassword = function () {
        this.resetPasswordVisible = !this.resetPasswordVisible;
        this.createUnitVisible = false;
        this.createUserVisible = false;
        this.editUserVisible = false;
    };
    ListComponent.prototype.toggleCreateUnit = function () {
        this.createUnitVisible = !this.createUnitVisible;
        this.createUserVisible = false;
        this.editUserVisible = false;
        this.resetPasswordVisible = false;
    };
    ListComponent.prototype.toggleCreateUser = function () {
        this.createUserVisible = !this.createUserVisible;
        this.createUnitVisible = false;
        this.editUserVisible = false;
        this.resetPasswordVisible = false;
    };
    ListComponent.prototype.cancelEdit = function () {
        this.createUserVisible = false;
        this.createUnitVisible = false;
        this.editUserVisible = false;
        this.createMessageVisible = false;
        this.resetPasswordVisible = false;
    };
    ListComponent.prototype.toggleEditUser = function () {
        this.createUserVisible = false;
        this.createUnitVisible = false;
        this.editUserVisible = true;
        this.resetPasswordVisible = false;
    };
    ListComponent.prototype.deleteUser = function (user) {
        var _this = this;
        if (window.confirm('Användarens konto samt intervjuer, svar och kommentarer kopplade till kontot kommer att raderas. Är du säker på att du vill ta bort den här användaren?')) {
            user.spinnerDeleteUser = true;
            this.service.deleteUser(user.id).pipe(takeUntil(this.destroyed$)).subscribe(function (x) {
                user.spinnerDeleteUser = false;
                _this.loadUsers(false);
                _this.notify.success('userDeleted');
            });
        }
    };
    ListComponent.prototype.deleteUnit = function (unit) {
        var _this = this;
        this.service.deleteUnit(unit.id).pipe(takeUntil(this.destroyed$)).subscribe(function (x) {
            _this.loadUnits(true);
            _this.notify.success('unitDeleted');
        });
    };
    ListComponent.prototype.onUnitCreated = function (unit) {
        this.units.push({ id: unit.uId, name: unit.uName });
    };
    ListComponent.prototype.saveUnit = function (unit) {
        var _this = this;
        this.updating = true;
        this.service.updateUnit(unit).pipe(takeUntil(this.destroyed$)).subscribe(function () {
            unit.editingUnit = false;
            _this.updating = false;
            _this.notify.success('unitUpdated');
        });
    };
    return ListComponent;
}(BaseComponent));
export { ListComponent };
