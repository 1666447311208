/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-unit.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../components/button/button.component.ngfactory";
import * as i4 from "../../../components/button/button.component";
import * as i5 from "@angular/common";
import * as i6 from "./add-unit.component";
import * as i7 from "../../../services/data-service.service";
import * as i8 from "../../../services/notification.service";
var styles_AddUnitComponent = [i0.styles];
var RenderType_AddUnitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddUnitComponent, data: {} });
export { RenderType_AddUnitComponent as RenderType_AddUnitComponent };
function View_AddUnitComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert green"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enheten \u00E4r nu skapad"]))], null, null); }
function View_AddUnitComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Det gick inte att skapa enheten"]))], null, null); }
function View_AddUnitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Skapa ny enhet"])), (_l()(), i1.ɵeld(3, 0, null, null, 23, "table", [["class", "table"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 21, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Namn"])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "input", [["class", "form-control"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.model.name = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(16, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 8, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(20, 4308992, null, 0, i4.ButtonComponent, [], { text: [0, "text"], color: [1, "color"], right: [2, "right"], useSpinner: [3, "useSpinner"], showSpinner: [4, "showSpinner"], debug: [5, "debug"], disabled: [6, "disabled"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(22, 4308992, null, 0, i4.ButtonComponent, [], { text: [0, "text"], right: [1, "right"], useSpinner: [2, "useSpinner"], debug: [3, "debug"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddUnitComponent_2)), i1.ɵdid(24, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddUnitComponent_3)), i1.ɵdid(26, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.model.name; _ck(_v, 14, 0, currVal_7); var currVal_8 = "Skapa"; var currVal_9 = "success"; var currVal_10 = true; var currVal_11 = false; var currVal_12 = _co.addingUnit; var currVal_13 = false; var currVal_14 = ((_co.model.name == null) || (_co.model.name === "")); _ck(_v, 20, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = "Avbryt"; var currVal_16 = true; var currVal_17 = false; var currVal_18 = false; _ck(_v, 22, 0, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = _co.addUnitSuccess; _ck(_v, 24, 0, currVal_19); var currVal_20 = _co.error.hasError; _ck(_v, 26, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 16).ngClassValid; var currVal_5 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AddUnitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddUnitComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AddUnitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-unit", [], null, null, null, View_AddUnitComponent_0, RenderType_AddUnitComponent)), i1.ɵdid(1, 180224, null, 0, i6.AddUnitComponent, [i7.DataService, i8.NotificationService], null, null)], null, null); }
var AddUnitComponentNgFactory = i1.ɵccf("app-add-unit", i6.AddUnitComponent, View_AddUnitComponent_Host_0, { visible: "visible" }, { onCreated: "onCreated", onCancel: "onCancel" }, []);
export { AddUnitComponentNgFactory as AddUnitComponentNgFactory };
