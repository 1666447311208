import * as tslib_1 from "tslib";
import { LoginService } from '../../services/login.service';
import { BaseComponent } from '../../components/base-component';
import { Router } from '@angular/router';
import { DataService } from '../../services/data-service.service';
var LoginComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginComponent, _super);
    function LoginComponent(service, router, dataService) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.router = router;
        _this.dataService = dataService;
        _this.isLoading = false;
        service.Stream.subscribe(function (x) {
            _this.loggedIn = x.isLoggedIn;
            _this.loggedInAs = x.userName;
            _this.logginExpires = x.expires;
        });
        _this.service.isLoggedIn();
        return _this;
    }
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.isLoading = true;
        this.service.login(this.usr, this.psw).subscribe(function (success) {
            if (success) {
                _this.router.navigateByUrl('/landing');
            }
            _this.isLoading = false;
        });
    };
    LoginComponent.prototype.logout = function () {
        this.service.logout();
    };
    return LoginComponent;
}(BaseComponent));
export { LoginComponent };
