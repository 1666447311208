import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { BaseComponent } from '../../components/base-component';
import { Router } from '@angular/router';
import { DataService } from '../../services/data-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent {
  psw: string;
  usr: string;
  loggedIn: boolean;
  loggedInAs: string;
  logginExpires: string;
  UserId: string;
  NewPassword: string;

  isLoading = false;


  constructor(private service: LoginService, private readonly router: Router, private dataService: DataService) {
    super();
    service.Stream.subscribe(x => {
      this.loggedIn = x.isLoggedIn;
      this.loggedInAs = x.userName;
      this.logginExpires = x.expires;
    });
    this.service.isLoggedIn();
  }

  login() {
    this.isLoading = true;
    this.service.login(this.usr, this.psw).subscribe(success => {
      if (success) {
        this.router.navigateByUrl('/landing');
      }
      this.isLoading = false;
    });
  }

  logout() {
    this.service.logout();
  }
}
