<mat-spinner *ngIf="isLoading"></mat-spinner>
<div class="col-md-12" *ngIf="!isLoading">
  <h2 class="text-center" style="font-family: Tahoma">User guide</h2>
  <hr />
    <div class="nav-card card" style="width: 100%">
      <img src="assets/navbarVersion2.PNG" class="nav-card-img-top" alt="...">
      <div class="card-body">
        <h2>{{'navbarHeader' | translate}}</h2>
        <p class="card-text">{{'navbar' | translate}}</p>
      </div>
    </div>
    <span class="spacer"></span>

    <div class="card" style="width: 100%;">
      <h2>{{'guideQuestionsHeader' | translate}}</h2>
      <img class="card-img-top" src="assets/translationsNyV2.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{'guideQuestionsHeader2' | translate}}</h5>
        <p>{{'guideQuestion' | translate}}</p>
        <h6>Info Text</h6>
        <p>{{'guideQuestionInfoText' | translate}}</p>
      </div>
    </div>
    <span class="spacer"></span>

    <div class="card" style="width: 100%;">
      <h2>{{'questionTypeHeader' | translate}}</h2>
      <p class="card-body description">{{'questionType' | translate}}</p>
      <img class="card-img-top" src="assets/questionType1NyastV3.png" alt="Card image cap">
      <div class="card-body">
        <p><span style="font-weight: bold;">1. </span>{{'questionType1' | translate}}</p>
        <p><span style="font-weight: bold;">2. </span> {{'questionType2' | translate}}</p>
      </div>
      <img class="card-img-top" src="assets/questionType2Nyast.png" alt="Card image cap" />
      <div class="card-body">
        <p>{{'questionTypeTranslation' | translate}}</p>
      </div>
    </div>
    <span class="spacer"></span>
    
    <div class="card" style="width: 100%;">
      <h2>{{'pageTextHeader' | translate}}</h2>
      <img class="card-img-top" src="assets/pageTextsNyV3.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{'pageTextHeader' | translate}}</h5>
        <p>{{'pageText' | translate}}</p>
        <p><span style="font-weight: bold;">1. </span>{{'pageText1' | translate}}</p>
        <p><span style="font-weight: bold;">2. </span>{{'pageText2' | translate}}</p>
        <p><span style="font-weight: bold;">3. </span>{{'pageText3' | translate}}</p>
      </div>
    </div>
    <span class="spacer"></span>

    <div class="card" style="width: 100%;">
      <h2>{{'criteriaHeader' | translate}}</h2>
      <img class="card-img-top" src="assets/criteriaNyV2.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{'criteriaHeader' | translate}}</h5>
        <p>{{'criteria' | translate}}</p>
      </div>
    </div>
    <span class="spacer"></span>

    <div class="card" style="width: 100%;">
      <h2>{{'backgroundHeaderGuide' | translate}}</h2>
      <img class="card-img-top" src="assets/backgroundNyV2.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{'backgroundHeaderGuide' | translate}}</h5>
        <p>{{'background' | translate}}</p>
      </div>
    </div>

    <span class="spacer"></span>

    <div class="card" style="width: 100%;">
      <h2>{{'guideSubstanceListHeader' | translate}}</h2>
      <img class="card-img-top" src="assets/substanceList.PNG" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{'guideSubstanceListHeader' | translate}}</h5>
        <p>{{'guideSubstanceInfoText' | translate}}</p>
        <p><span style="font-weight: bold;">1. </span>{{'guideSubstanceText1' | translate}}</p>
        <p>{{'guideSubstanceText2' | translate}}</p>
        <p><span style="font-weight: bold;">2. </span>{{'guideSubstanceText3' | translate}}</p>
      </div>
    </div>

    <span class="spacer"></span>

    <div class="card" style="width: 100%;">
      <h2>{{'categoryHeaderGuide' | translate}}</h2>
      <img class="card-img-top" src="assets/Category.PNG" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{'categoryHeaderGuide' | translate}}</h5>
        <p>{{'categoryTextGuide' | translate}}</p>
      </div>
    </div>

  </div>
  
