import { LoginService } from './login.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./login.service";
var DataService = /** @class */ (function () {
    function DataService(http, loginService) {
        var _this = this;
        this.http = http;
        this.loginService = loginService;
        this.base = environment.apiUrl; //Load from environment.ts
        this.token_key = "admin_token";
        this.loginService.Stream.subscribe(function (x) {
            _this.isLoggedIn = x.isLoggedIn;
            if (!x.isLoggedIn)
                _this.token = null;
        });
        this.loginService.isLoggedIn();
    }
    DataService.prototype.generateNewPassword = function (email) {
        return this.http.get(this.base + "/Account/GenerateNewPassword?email=" + email);
    };
    DataService.prototype.saveCriterias = function (criterias) {
        return this.http.post(this.base + "/translation/SaveCriterias", criterias);
    };
    DataService.prototype.getCriterias = function () {
        return this.http.get(this.base + "/translation/GetCriterias");
    };
    DataService.prototype.getCategorys = function () {
        return this.http.get(this.base + "/translation/QuestionCategorys");
    };
    DataService.prototype.saveCategories = function (categories) {
        return this.http.post(this.base + "/translation/SaveQuestionCategory", categories);
    };
    DataService.prototype.getDrugs = function () {
        return this.http.get(this.base + "/translation/GetDrugs");
    };
    DataService.prototype.updateSubstance = function (data) {
        return this.http.put(this.base + "/translation/UpdateSubstanceTranslation", data);
    };
    DataService.prototype.updateActiveSubstance = function (data) {
        return this.http.put(this.base + "/translation/UpdateActiveSubstance", data);
    };
    DataService.prototype.moveSubstanceGroup = function (substanceGroupId, drugGroupId) {
        return this.http.put(this.base + "/translation/MoveSubstanceGroup", { substanceGroupId: substanceGroupId, drugGroupId: drugGroupId });
    };
    DataService.prototype.addNewSubstance = function (drugGroupId, substanceName) {
        return this.http.post(this.base + "/translation/AddSubstance", { drugGroupId: drugGroupId, substanceName: substanceName });
    };
    DataService.prototype.moveTranslation = function (key, culture, group, newGroup) {
        return this.http.put(this.base + "/translation/movetranslation?newGroupId=" + newGroup, { key: key, culture: culture, group: group });
    };
    DataService.prototype.moveTranslationGroup = function (groupId, translationGroupKey) {
        return this.http.put(this.base + "/translation/MoveTranslationGroup", { groupId: groupId, translationGroupKey: translationGroupKey });
    };
    DataService.prototype.updateTranslation = function (key, culture, group, text) {
        return this.http.put(this.base + "/translation/updatetranslation", { key: key, culture: culture, text: text, group: group });
    };
    DataService.prototype.updateGroup = function (group) {
        return this.http.put(this.base + "/translation/UpdateGroup", group);
    };
    DataService.prototype.deleteGroup = function (id) {
        return this.http.delete(this.base + "/translation/deleteGroup?id=" + id);
    };
    DataService.prototype.addGroup = function (name) {
        return this.http.post(this.base + "/translation/addgroup", { name: name });
    };
    DataService.prototype.getGroups = function (includeTranslations) {
        return this.http.get(this.base + "/translation/getgroups?includeTranslations=" + includeTranslations);
    };
    DataService.prototype.getGroup = function (groupId) {
        return this.http.get(this.base + "/translation/getgroup?groupId=" + groupId);
    };
    DataService.prototype.getLanguages = function () {
        return this.http.get(this.base + "/translation/getLanguages");
    };
    DataService.prototype.exportUsers = function () {
        return this.http.get(this.base + "/account/exportusers");
    };
    DataService.prototype.exportInterviews = function () {
        return this.http.get(this.base + "/account/exportinterviews");
    };
    DataService.prototype.listUsers = function () {
        return this.http.get(this.base + '/account/listUsers');
    };
    DataService.prototype.listUnits = function () {
        return this.http.get(this.base + '/account/listUnits');
    };
    DataService.prototype.listQuestionTypes = function () {
        return this.http.get(this.base + '/translation/questiontypes');
    };
    DataService.prototype.deleteUser = function (id) {
        return this.http.post(this.base + '/account/removeuser', { userId: id });
    };
    DataService.prototype.createUser = function (user) {
        return this.http.post(this.base + '/account/register', user);
    };
    DataService.prototype.getQuestions = function () {
        return this.http.get(this.base + '/question');
    };
    DataService.prototype.createInterview = function (interview) {
        return this.http.post(this.base + '/interview/create', interview);
    };
    DataService.prototype.createUnit = function (name) {
        return this.http.get(this.base + '/account/addunit?name=' + name);
    };
    DataService.prototype.deleteUnit = function (unitId) {
        return this.http.get(this.base + '/account/removeunit?id=' + unitId);
    };
    DataService.prototype.editUser = function (user) {
        return this.http.post(this.base + '/account/editUser', user);
    };
    DataService.prototype.getUser = function (id) {
        return this.http.get(this.base + '/account/getUser?id=' + id);
    };
    DataService.prototype.updateUnit = function (unit) {
        return this.http.post(this.base + '/account/EditUnit', unit);
    };
    DataService.prototype.saveQuestionTypeTranslations = function (data) {
        return this.http.post(this.base + '/translation/saveQuestionTypeTranslations', data);
    };
    DataService.prototype.listQuestionTranslations = function () {
        return this.http.get(this.base + '/translation/questions');
    };
    DataService.prototype.saveQuestionTranslations = function (data) {
        return this.http.post(this.base + '/translation/saveQuestionTranslations', data);
    };
    DataService.prototype.getCodeBackgrounds = function () {
        return this.http.get(this.base + '/translation/GetCodeBackgrounds');
    };
    DataService.prototype.saveCodeBackgrounds = function (data) {
        return this.http.post(this.base + '/translation/SaveCodeBackgrounds', data);
    };
    DataService.prototype.generateRegLink = function () {
        return this.http.get(this.base + '/account/GenerateRegistrationLink');
    };
    DataService.prototype.getTotalInterviews = function () {
        return this.http.get(this.base + '/interview/GetTotalInterviews');
    };
    DataService.prototype.getInterviewsLastMonth = function () {
        return this.http.get(this.base + '/interview/GetInterviewsLastMonth');
    };
    DataService.prototype.getUserCount = function () {
        return this.http.get(this.base + '/account/GetUserCount');
    };
    DataService.prototype.getInterviewsCount = function () {
        return this.http.get(this.base + '/interview/GetInterviewsCount');
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject(i1.HttpClient), i0.inject(i2.LoginService)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
